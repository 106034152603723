import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions } from "@angular/http";
import { Path } from "../path";

@Injectable()
export class AdminService {
  readonly rootUrl = Path.URL;

  constructor(private http: HttpClient) {
  }

  getImage(id) {
    return this.http.get(this.rootUrl + '/api/Image/GetImage?id=' + id);
  }

  getAllNews(lang, page) {
    return this.http.get(this.rootUrl + '/api/News/GetAll?lang=' + lang + '&page=' + page);
  }

  getMaxPage(lang, page) {
    return this.http.get(this.rootUrl + '/api/News/GetMaxPage?lang=' + lang + '&page=' + page);
  }

  getNewsById(id) {
    return this.http.get(this.rootUrl + '/api/News/GetById?id=' + id);
  }

  deleteNews(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/News/Delete?id='+id, { headers: requestHeader });
  }

  addNews(titleHr, textHr, lang, images, date) {
    var data = ({ "TitleHR": titleHr, "TextHR": textHr, "Language": lang, "Images": images, "Date":date });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/News/Add', data, { headers: requestHeader });
  }

  updateNews(id, titleHr, textHr, lang, images, date) {
    var data = ({ "Id": id, "TitleHR": titleHr, "TextHR": textHr, "Language": lang, "Images": images, "Date": date });
    console.log(data);
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/News/Edit', data, { headers: requestHeader });
  }

  getReferenceTypes(lang) {
    return this.http.get(this.rootUrl + '/api/ReferenceType/GetAll?lang=' + lang);
  }

  getReferenceTypeById(id) {
    return this.http.get(this.rootUrl + '/api/ReferenceType/GetById?id=' + id);
  }

  addReferenceType(nameHR, nameEN, nameDE) {
    var data = ({ "NameHR": nameHR, "NameEN": nameEN, "NameDE": nameDE });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/ReferenceType/Add', data, { headers: requestHeader });
  }

  updateReferenceType(id, nameHR, nameEN, nameDE) {
    var data = ({"Id":id, "NameHR": nameHR, "NameEN": nameEN, "NameDE": nameDE });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/ReferenceType/Edit', data, { headers: requestHeader });
  }

  deleteReferenceType(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/ReferenceType/Delete?id=' + id, { headers: requestHeader });
  }

  getAllReferences(lang, page) {
    return this.http.get(this.rootUrl + '/api/Reference/GetAll?lang=' + lang + '&page=' + page);
  }

  getReferenceMaxPage(lang, page) {
    return this.http.get(this.rootUrl + '/api/Reference/GetMaxPage?lang=' + lang + '&page=' + page);
  }

  getReferenceById(id) {
    return this.http.get(this.rootUrl + '/api/Reference/GetById?id=' +id);
  }

  addReference(nameHR, nameEN, nameDE, noteHR, noteEN, noteDE, image, imageThumb, isPriority, location, typeId, ext) {
    var data = ({"NameHR": nameHR, "NameEN": nameEN, "NameDE": nameDE, "NoteHR": noteHR, "NoteEN": noteEN, "NoteDE": noteDE,
      "ImagePath": image, "ThumbPath": imageThumb, "IsPriority": isPriority, "Location":location, "ReferenceTypeId":typeId, "Ext":ext });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Reference/Add', data, { headers: requestHeader });
  }

  updateReference(id, nameHr, noteHr, nameEn, noteEn, nameDe, noteDe, location, isPriority, image, imageThumb, imageId, typeId, ext) {
    var data = ({ "Id":parseInt(id), "NameHR": nameHr, "NameEN": nameEn, "NameDE": nameDe, "NoteHR": noteHr, "NoteEN": noteEn, "NoteDE": noteDe,
      "ImagePath": image, "ThumbPath": imageThumb, "IsPriority": isPriority, "Location": location, "ReferenceTypeId": parseInt(typeId),
      "ImageId": parseInt(imageId), "Ext":ext
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Reference/Edit', data, { headers: requestHeader });
  }

  deleteReference(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Reference/Delete?id=' + id, { headers: requestHeader });
  }

  getAllPartners(page) {
    return this.http.get(this.rootUrl + '/api/Partner/GetAll?page=' + page);
  }

  getPartnerMaxPage(page) {
    return this.http.get(this.rootUrl + '/api/Partner/GetMaxPage?page=' + page);
  }

  getPartnerById(id) {
    return this.http.get(this.rootUrl + '/api/Partner/GetById?id=' + id);
  }

  addPartner(name, website, image, imageThumb, ext) {
    var data = ({ "NameKey": name, "ImagePath": image, "ThumbPath": imageThumb, "Website": website, "Ext":ext  });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Partner/Add', data, { headers: requestHeader });
  }

  updatePartner(id, imageId, name, website, image, imageThumb, ext) {
    var data = ({ "Id": id, "ImageId": imageId, "NameKey": name, "ImagePath": image, "ThumbPath": imageThumb, "Website": website, "Ext":ext });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Partner/Edit', data, { headers: requestHeader });
  }

  deletePartner(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Partner/Delete?id=' + id, { headers: requestHeader });
  }
  
  getHomeContent() {
    return this.http.get(this.rootUrl + '/api/Home/GetAll');
  }

  getHomeContentById(id) {
    return this.http.get(this.rootUrl + '/api/Home/GetById?id=' + id);
  }

  addHomeContent(tekstHr, tekstEn, tekstDe, image, imageThumb, ext) {
    var data = ({ "TextHR": tekstHr, "TextEN": tekstEn, "TextDE": tekstDe, "ImagePath": image, "ThumbPath": imageThumb, "Ext":ext });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Home/Add', data, { headers: requestHeader });
  }

  updateHomeContent(id, tekstHr, tekstEn, tekstDe, image, imageThumb, ext) {
    var data = ({ "Id": id, "TextHR": tekstHr, "TextEN": tekstEn, "TextDE": tekstDe, "ImagePath": image, "ThumbPath": imageThumb, "Ext": ext });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Home/Edit', data, { headers: requestHeader });
  }

  deleteHomeContent(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Home/Delete?id=' + id, { headers: requestHeader });
  }

  getAboutUs() {
    return this.http.get(this.rootUrl + '/api/AboutUs/GetAboutUs');
  }

  getAboutUsById(id) {
    return this.http.get(this.rootUrl + '/api/AboutUs/GetById?id='+id);
  }

  updateAboutUsContent(id, imageId, titleHr, titleEn, titleDe, tekstHr, tekstEn, tekstDe, image, imageThumb, ext) {
    var data = ({
      "Id": id, "ImageId": imageId, "TitleHR": titleHr, "TitleEN": titleEn, "TitleDE": titleDe, "TextHR": tekstHr, "TextEN": tekstEn,
      "TextDE": tekstDe, "ImagePath": image, "ThumbPath": imageThumb, "Ext":ext
    });
    console.log(data);
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/AboutUs/Edit', data, { headers: requestHeader });
  }

  getActivities() {
    return this.http.get(this.rootUrl + '/api/Activity/GetActivities');
  }

  getActivitiesById(id) {
    return this.http.get(this.rootUrl + '/api/Activity/GetById?id='+id);
  }

  updateActivity(id, titleHr, textHr, titleEn, textEn, titleDe, textDe, images) {
    var data = ({ "Id": id, "TitleHR": titleHr, "TextHR": textHr, "TitleEN": titleEn, "TextEN": textEn, "TitleDE": titleDe, "TextDE": textDe, "Images": images });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Activity/Edit', data, { headers: requestHeader });
  }

  getISO() {
    return this.http.get(this.rootUrl + '/api/ISO/GetISO');
  }

  updateISO(id, teksthr, teksten, tekstde) {
    var data = ({ "Id": id, "TextHR": teksthr, "TextEN": teksten, "TextDE": tekstde, "TitleHR":'X', "TitleEN":'X', "TitleDE":'X'});
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/ISO/Edit', data, { headers: requestHeader });
  }

  getSettings() {
    return this.http.get(this.rootUrl + '/api/Settings/GetSettings');
  }

  updateSettings(id, emailAnketa, emailPrimjedbe, emailNatjecaji, emailKontakt, emailServis, senderEmail, senderPassword) {
    var data = ({
      "Id": id, "EmailAnketa": emailAnketa, "EmailPrimjedbe": emailPrimjedbe, "EmailNatjecaji": emailNatjecaji,
      "EmailKontakt": emailKontakt, "EmailServis": emailServis, "SenderEmail":senderEmail, "SenderPassword":senderPassword
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Settings/Edit', data, { headers: requestHeader });
  }

  addQuestions(textHr, textEn, textDe) {
    var data = ({ "TekstHR": textHr, "TekstEN": textEn,"TekstDE": textDe});
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Question/Add', data, { headers: requestHeader });
  }

  updateQuestions(id, textHr, textEn, textDe) {
    var data = ({"Id":id, "TekstHR": textHr, "TekstEN": textEn, "TekstDE": textDe });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Question/Edit', data, { headers: requestHeader });
  }

  getQuestions(lang) {
    return this.http.get(this.rootUrl + '/api/Question/GetAll?lang='+lang);
  }

  getQuestionById(id) {
    return this.http.get(this.rootUrl + '/api/Question/GetById?id=' + id);
  }

  deleteQuestion(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Question/Delete?id=' + id, { headers: requestHeader });
  }

  getImageThumb(id) {
    return this.http.get(this.rootUrl + '/api/Image/GetThumb?id=' + id);
  }

  getUser() {
    return this.http.get(this.rootUrl + '/api/Autentification/GetUser');
  }

  updateUser(id, username, password) {
    var data = ({ "Id": id, "Username": username, "PasswordHash": password});
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Autentification/Edit', data, { headers: requestHeader });
  }

  logout() {
    return this.http.get(this.rootUrl + '/api/Autentification/Logout');
  }

  deleteNewsImage(id) {
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/News/DeleteImage?id=' + id, { headers: requestHeader });
  }
}
