import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../service/translate/translate.service';
import { Router } from '../../../../node_modules/@angular/router';
import { LanguageService } from '../../service/language.service';
import { SiteService } from '../../service/site.service';
import { AboutUs } from '../../Models/aboutus.model';
import { Activity } from '../../Models/activity.model';

declare var $: any;

@Component({
  selector: 'at-header',
  templateUrl: './header.component.html',
  providers: [TranslateService, LanguageService]
})

export class HeaderComponent implements OnInit {

  public lang: string;
  public aboutUs: AboutUs[];
  public activities: Activity[];

  public urlPartneri: string;
  public urlReference: string;
  public urlNovosti: string;
  public urlHome: string;
  public urlServisnaSluzba: string;
  public urlMisija: string;
  public urlNatjecaji: string;
  public urlStipendije: string;
  public urlProfil: string;
  public urlKompletnost: string;
  public urlEfikasnost: string;
  public urlProjektiranje: string;
  public urlBim: string;
  public urlRegulacija: string;
  public urlMontaza: string;
  public urlServis: string;
  public urlOdrzavanje: string;
  public urlAudit: string;

  public urlISO: string;
  public urlSurvey: string;
  public urlReviews: string;
  public urlKontakt: string;

  public textMisija: string;
  public textNatjecaj: string;
  public textStipendije: string;
  public textProfil: string;
  public textKompletnost: string;
  public textEfikasnost: string;

  public textProjektiranje: string;
  public textBim: string;
  public textRegulacija: string;
  public textServis: string;
  public textMontaza: string;
  public textOdrzavanje: string;
  public textAudit: string;

  constructor(private _translate: TranslateService, private router: Router, private LanguageService: LanguageService,
    private SiteService: SiteService) { }

  ngOnInit() {
    this.loadText();
    this.loadContent();
    this.loadActivities();
    this.LanguageService.changeIcon(this.lang);

    this.checkUrls();
  }

  checkUrls() {
    this.textMisija = this._translate.instant('_MISIJA_VIZIJA');
    this.textNatjecaj = this._translate.instant('_NATJECAJI');
    this.textStipendije = this._translate.instant('_STIPENDIJE');
    this.textProfil = this._translate.instant('_PROFIL_TVRTKE');
    this.textKompletnost = this._translate.instant('_KOMPLETNOST_USLUGE');
    this.textEfikasnost = this._translate.instant('_ENERGETSKA_EFIKASNOST');

    this.textProjektiranje = this._translate.instant('_PROJEKTIRANJE');
    this.textBim = this._translate.instant('_BIM_PROJEKTIRANJE_INSTALACIJA');
    this.textRegulacija = this._translate.instant('_AUTOMATSKA_REGULACIJA');
    this.textMontaza = this._translate.instant('_MONTAZA');
    this.textServis = this._translate.instant('_SERVIS');
    this.textOdrzavanje = this._translate.instant('_ODRZAVANJE');
    this.textAudit = this._translate.instant('_ENERGETSKI_AUDIT');

    this.urlPartneri = "/" + this.lang + "/" + this._translate.instant('_PARTNERI');
    this.urlReference = "/" + this.lang + "/" + this._translate.instant('_REFERENCE');
    this.urlNovosti = "/" + this.lang + "/" + this._translate.instant('_NOVOSTI');
    this.urlHome = "/" + this.lang + "/" + this._translate.instant('_POCETNA');
    this.urlServisnaSluzba = "/" + this.lang + "/" + this._translate.instant('_SERVISNA_SLUZBA2');

    var name = this._translate.instant('_STA_JE_ISO');
    var url = name.split(' ').join('_');
    url = url.split('?').join('');
    url = url.split('Š').join('S');
    this.urlISO = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_ISPITIVANJE_ZADOVOLJSTVA_KLIJENATA');
    var url = name.split(' ').join('_');
    this.urlSurvey = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_PRIMJEDBE_KOMENTARI');
    var url = name.split(' ').join('_');
    this.urlReviews = "/" + this.lang + "/" + url;

    var url = this._translate.instant('_KONTAKT');
    this.urlKontakt = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_MISIJA_VIZIJA');
    var url = name.split(' ').join('_');
    this.urlMisija = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_NATJECAJI');
    name = name.split('č').join('c');
    var url = name.split(' ').join('_');
    this.urlNatjecaji = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_STIPENDIJE');
    var url = name.split(' ').join('_');
    this.urlStipendije = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_PROFIL_TVRTKE');
    var url = name.split(' ').join('_');
    this.urlProfil = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_KOMPLETNOST_USLUGE');
    var url = name.split(' ').join('_');
    this.urlKompletnost = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_ENERGETSKA_EFIKASNOST');
    var url = name.split(' ').join('_');
    this.urlEfikasnost = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_PROJEKTIRANJE');
    var url = name.split(' ').join('_');
    this.urlProjektiranje = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_BIM_PROJEKTIRANJE_INSTALACIJA');
    var url = name.split(' ').join('_');
    this.urlBim = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_AUTOMATSKA_REGULACIJA');
    var url = name.split(' ').join('_');
    this.urlRegulacija = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_MONTAZA');
    name = name.split('ž').join('z');
    var url = name.split(' ').join('_');
    this.urlMontaza = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_ODRZAVANJE');
    name = name.split('ž').join('z');
    var url = name.split(' ').join('_');
    this.urlOdrzavanje = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_SERVIS');
    var url = name.split(' ').join('_');
    this.urlServis = "/" + this.lang + "/" + url;

    var name = this._translate.instant('_ENERGETSKI_AUDIT');
    var url = name.split(' ').join('_');
    this.urlAudit = "/" + this.lang + "/" + url;
  }

  close() {
    if ($("li.dropdown").hasClass("open")) {
      $("li.dropdown").removeClass("open");
    }
  }

  loadText() {
    this.lang = sessionStorage.getItem("Jezik");

    if (this.lang == undefined || this.lang == null)
      this.lang = 'hr';

    this._translate.use(this.lang);
  }

  selectHR() {
    if (this.lang != "hr") {
      var r = this.router.url.substring(1, this.router.url.length);
      if ((r.match(new RegExp("/", "g")) || []).length == 2) {
        var id = r.substring(r.lastIndexOf("/"), r.length);
        r = r.substring(0, r.lastIndexOf("/"));
      }
      this.lang = "hr";
      sessionStorage.removeItem("Jezik");
      sessionStorage.setItem("Jezik", "hr");
      this._translate.use(this.lang);
      var route = this._translate.instant(r);
      if (id != undefined)
        route = route + id;
      this.router.navigate([route]);
    }
    if ($("li.dropdown").hasClass("open")) {
      $("li.dropdown").removeClass("open");
    }
  }

  selectEN() {
    if (this.lang != "en") {
      var r = this.router.url.substring(1, this.router.url.length);
      if ((r.match(new RegExp("/", "g")) || []).length == 2) {
        var id = r.substring(r.lastIndexOf("/"), r.length);
        r = r.substring(0, r.lastIndexOf("/"));
      }
      this.lang = "en";
      sessionStorage.removeItem("Jezik");
      sessionStorage.setItem("Jezik", "en");
      this._translate.use(this.lang);
      var route = this._translate.instant(r);
      if (id != undefined)
        route = route + id;
      this.router.navigate([route]);
    }
    if ($("li.dropdown").hasClass("open")) {
      $("li.dropdown").removeClass("open");
    }
  }

  selectDE() {
    if (this.lang != "de") {
      var r = this.router.url.substring(1, this.router.url.length);
      if ((r.match(new RegExp("/", "g")) || []).length == 2) {
        var id = r.substring(r.lastIndexOf("/"), r.length);
        r = r.substring(0, r.lastIndexOf("/"));
      }
      this.lang = "de";
      sessionStorage.removeItem("Jezik");
      sessionStorage.setItem("Jezik", "de");
      this._translate.use(this.lang);
      var route = this._translate.instant(r);
      if (id != undefined)
        route = route + id;
      this.router.navigate([route]);
    }

    if ($("li.dropdown").hasClass("open")) {
      $("li.dropdown").removeClass("open");
    }
  }

  loadContent() {
    this.SiteService.getAboutUsContent(this.lang).subscribe((data: any) => {
      this.aboutUs = data;
    });
  }

  loadActivities() {
    this.SiteService.getActivitiesContent(this.lang).subscribe((data: any) => {
      this.activities = data;
    });
  }
}
