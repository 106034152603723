export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  //ERROR
  '_ERROR': 'An error has occurred. Please try again!',
  '_ERROR_IMAGE': 'Please enter image!',
  '_USPJESNO_OBRISANO': 'You have successfully delete data!',
  '_OBAVEZNA_POLJA': 'You need to enter all the required information for a particular language!',
  '_USPJESNO_DODANA_NOVOST': 'You have successfully added a new data!',
  '_USPJESNO_IZMJENJENA_NOVOST': 'You have successfully updated news!',
  '_POLJA_OBAVEZNA': 'All fields are required!',
  '_USPJESNO_DODAN_TIP_REFERENCE': 'You have successfully added a new reference type!',
  '_NIJE_MOGUCE_OBRISATI': 'The data is in use, so it cannot be deleted!',
  '_USPJESNO_IZMJENJEN_TIP_REFERENCE': 'You have successfully updated a reference type!',
  '_USPJESNO_DODATA_REFERENCA': 'You have successfully added a new reference!',
  '_USPJESNO_IZMJENJENA_REFERENCA': 'You have successfully updated a reference!',
  '_USPJESNO_DODAN_PARTNER': 'You have successfully added a new partner!',
  '_USPJESNO_IZMJENJEN_PARTNER': 'You have successfully updated a partner!',
  '_USPJESNO_IZMJENJEN_SADRZAJ': 'You have sucessfully updated content!',
  '_USPJESNO_DODAN_SADRZAJ': 'You have successfully added new content!',
  '_NETACNI_PODACI': 'You have not entered correct user data!',
  '_DODAJ_PITANJA': 'Add questions',
  '_UNESI_TEKST_PITANJA': 'Enter question',
  '_EMAIL_ANKETA': 'Email survey',
  '_EMAIL_PRIMJEDBE': 'Email reviews',
  '_EMAIL_NATJECAJI': 'Email positions',
  '_EMAIL_KONTAKT': 'Email contact',
  '_EMAIL_SERVIS': 'Email service',
  '_LOZINKA_VALIDACIJA': 'Password must have at least 8 characters!',
  '_KORISNICKO_IME': 'Username',
  '_UNESI_KORISNICKO_IME': 'Enter username',
  '_LOZINKA': 'Password',
  '_UNESI_LOZINKU': 'Enter password',
  '_USPJESNA_PRIJAVA': 'You have successfully applied for the position!',
  '_JEZIK': 'Language',
  '_SLAZEM_SE_SA_PRAVILIMA': 'I agree with the privacy terms',
  '_DATUM': 'Date',

  //ADMIN
  '_DODAJ_NOVOST': 'Add news',
  '_NASLOV': 'Title',
  '_TEKST': 'Text',
  '_AKCIJE': 'Actions',
  '_UREDI': 'Edit',
  '_OBRISI': 'Delete',
  '_HRVATSKI_JEZIK': 'Croatian language',
  '_ENGLESKI_JEZIK': 'English language',
  '_NJEMACKI_JEZIK': 'German language',
  '_GALERIJA': 'Gallery:',
  '_GLAVNA_FOTOGRAFIJA': 'Primary image:',
  '_DODAJ_FOTOGRAFIJE': 'Add images',
  '_NAZAD': 'Back',
  '_SPREMI': 'Submit',
  '_NASTAVI': 'Save & continue',
  '_IZREZI': 'Crop',
  '_UNESI_NASLOV': 'Enter title',
  '_DODAJ_REFERENCU': 'Add a reference',
  '_LOKACIJA': 'Location',
  '_OPIS': 'Description',
  '_TIP_REFERENCE': 'Reference type',
  '_NAZIV': 'Name',
  '_UNESI_NAZIV': 'Enter name',
  '_DODAJ_TIP_REFERENCE': 'Add a reference type',
  '_ODBACI': 'Cancel',
  '_FOTOGRAFIJA': 'Image',
  '_UNESI_LOKACIJU': 'Enter location',
  '_PRIORITET': 'Priority',
  '_WEBSITE': 'Website',
  '_UNESI_WEBSITE': 'Enter website',
  '_DODAJ_PARTNERA': 'Add a partner',
  '_DODAJ_SADRZAJ': 'Add content',
  '_UNESI_TEKST': 'Enter text',
  '_PROCITAJTE_VISE':'Read more',
  '_ANKETA': 'Survey',
  '_POSTAVKE': 'Settings',

  //RUTE
  'hr/Pocetna': 'en/Home',
  'en/Home': 'en/Home',
  'de/Startseite': 'en/Home',

  'hr/Reference': 'en/References',
  'en/References': 'en/References',
  'de/Referenzen': 'en/References',

  'hr/Partneri': 'en/Partners',
  'en/Partners': 'en/Partners',
  'de/Partners': 'en/Partners',

  'hr/Novosti': 'en/News',
  'en/News': 'en/News',
  'de/Nachrichten': 'en/News',

  'hr/Misija_i_vizija': 'en/Mission_and_vision',
  'en/Mission_and_vision': 'en/Mission_and_vision',
  'de/Mission_und_vision': 'en/Mission_and_vision',

  'hr/Natjecaji': 'en/Open_positions',
  'en/Open_positions': 'en/Open_positions',
  'de/Wettbewerbe': 'en/Open_positions',

  'hr/Stipendije': 'en/Scholarships',
  'en/Scholarships': 'en/Scholarships',
  'de/Stipendien': 'en/Scholarships',

  'hr/Profil_tvrtke': 'en/Company_profile',
  'en/Company_profile': 'en/Company_profile',
  'de/Firmenprofile': 'en/Company_profile',

  'hr/Kompletnost_usluge': 'en/Completness_of_services',
  'en/Completness_of_services': 'en/Completness_of_services',
  'de/Service-komplettpaket': 'en/Completness_of_services',

  'hr/Energetska_efikasnost': 'en/Energy_efficiency',
  'en/Energy_efficiency': 'en/Energy_efficiency',
  'de/Energieefizienz': 'en/Energy_efficiency',

  'hr/Projektiranje': 'en/Hvac_design',
  'en/Hvac_design': 'en/Hvac_design',
  'de/Projektierung': 'en/Hvac_design',

  'hr/BIM_projektiranje_instalacija': 'en/BIM_installation_design',
  'en/BIM_installation_design': 'en/BIM_installation_design',
  'de/BIM_Installationsprojektierung': 'en/BIM_installation_design',

  'hr/Montaza': 'en/Installation',
  'en/Installation': 'en/Installation',
  'de/Montage': 'en/Installation',

  'hr/Automatska_regulacija': 'en/Automated_regulation',
  'en/Automated_regulation': 'en/Automated_regulation',
  'de/Automatische_Regulierung': 'en/Automated_regulation',

  'hr/Servis': 'en/Service',
  'en/Service': 'en/Service',
  'de/Kundenservice': 'en/Service',

  'hr/Odrzavanje': 'en/Preventive_maintenance',
  'en/Preventive_maintenance': 'en/Preventive_maintenance',
  'de/Wartung': 'en/Preventive_maintenance',

  'hr/Energetski_audit': 'en/Energy_audit',
  'en/Energy_audit': 'en/Energy_audit',
  'de/Der_Energieaudit': 'en/Energy_audit',

  'hr/Sto_je_ISO': 'en/What_is_ISO',
  'en/What_is_ISO': 'en/What_is_ISO',
  'de/Was_ist_ISO': 'en/What_is_ISO',

  'hr/Primjedbe_i_komentari': 'en/Comments_and_remarks',
  'en/Comments_and_remarks': 'en/Comments_and_remarks',
  'de/Kommentare_und_Anmerkungen': 'en/Comments_and_remarks',

  'hr/Kontakt': 'en/Contact',
  'en/Contact': 'en/Contact',
  'de/Kontakt': 'en/Contact',

  'hr/Servisna_sluzba': 'en/Call_center',
  'en/Call_center': 'en/Call_center',
  'de/Kundendienst': 'en/Call_center',

  'hr/Ispitivanje_zadovoljstva_klijenata': 'en/Customer_Satisfaction_Testing',
  'en/Customer_Satisfaction_Testing': 'en/Customer_Satisfaction_Testing',
  'de/Kundenzufriedenheitstest': 'en/Customer_Satisfaction_Testing',

  //HEADER
  '_ONAMA': 'About us',
  '_ONAMA2': 'AboutUs',
  '_DJELATNOSTI': 'What we do',
  '_REFERENCE': 'References',
  '_PARTNERI': 'Partners',
  '_NOVOSTI': 'News',
  '_KONTAKT': 'Contact',
  '_SERVISNA_SLUZBA': 'Service - call center',
  '_SERVISNA_SLUZBA2': 'Call_center',
  '_MISIJA_VIZIJA': 'Mission and vision',
  '_NATJECAJI': 'Open positions',
  '_STIPENDIJE': 'Scholarships',
  '_PROFIL_TVRTKE': 'Company profile',
  '_KOMPLETNOST_USLUGE': 'Completness of services',
  '_ENERGETSKA_EFIKASNOST': 'Energy efficiency',
  '_PROJEKTIRANJE': 'Hvac design',
  '_BIM_PROJEKTIRANJE_INSTALACIJA': 'BIM installation design',
  '_AUTOMATSKA_REGULACIJA': 'Automated regulation',
  '_MONTAZA': 'Installation',
  '_SERVIS': 'Service',
  '_ODRZAVANJE': 'Preventive maintenance',
  '_ENERGETSKI_AUDIT': 'Energy audit',
  '_POCETNA': 'Home',
  '_UPRAVLJANJE_KVALITETOM': 'Quality management',
  '_STA_JE_ISO': 'What is ISO?',
  '_ISPITIVANJE_ZADOVOLJSTVA_KLIJENATA': 'Customer Satisfaction Testing',
  '_PRIMJEDBE_KOMENTARI': 'Comments and remarks',


  //FOOTER
  '_BOSNA_HERCEGOVINA': 'BOSNIA AND HERZEGOVINA',
  '_HRVATSKA': 'CROATIA',
  '_DODATNE_INFORMACIJE': 'Additional information',
  '_CERTIFIKAT': 'CERTIFICATE ISO 9001',
  '_COPYRIGHT': 'All rights reserved',

  //NATJECAJI
  '_NATJECAJI_PARAGRAF_1': 'Unfortunately we have no available positions open at this time.',
  '_NATJECAJI_PARAGRAF_2': 'If you want to show your  interest, please send your resume and cover letter to alfatherm@alfatherm.com',
  '_PRIJAVITE_SE': 'APPLY',
  '_IME': 'First name',
  '_EMAIL': 'E-mail',
  '_TELEFON': 'Phone',
  '_PORUKA': 'Message',
  '_ZIVOTOPIS': 'CV',
  '_SIGURNOSNI_KOD': 'Security Code',
  '_POSALJI': 'Send',
  '_PREZIME': 'Lastname',
  '_TVRTKA': 'Company',
  '_ADRESA_TVRTKE': 'Location',
  '_KOMENTAR': 'Comment',
  '_MOBITEL': 'Mobile',
  '_OPIS_KVARA': 'Description',
  '_PRIJAVI_KVAR': 'Report',
  '_USPJESNO_OSTAVLJEN_KOMENTAR': 'You have successfully submitted a comment for ALFA THERM d.o.o.',
  '_OZNACITE_CHECKBOX': 'Before submitting your personal data, you need to agree with the privacy statement.',
  '_UNESITE_KOMENTAR': 'Before you submit a review, you need to enter a comment.',

  '_USPJESNO_POSLANA_ANKETA': 'You have successfully completed the survey.',
  '_POLJE_KLIJENT_OBAVEZNO': 'Before submitting a survey, you need to fill in the client field.',
  '_POPUNITE_SVA_PITANJA': 'Before submitting a survey, it is necessary to answer all the questions asked by survey.',

  '_USPJESNO_POSLANA_PORUKA': 'Your message has been received. Alfa Therm team will answer you as soon as possible.',
  '_USPJESNO_PRIJAVLJEN_KVAR': 'Your malfunction has been received.',
  '_PRIHVATI': 'Accept',
  '_RECAPTCHA': 'Verification failed!',
  '_GDPR_NORMA': 'Alfa Therm d.o.o is the owner of www.alfatherm.com.We have created this Privacy Statement to demonstrate that we care about our users privacy and to inform them about ways of collecting and processing personal information on this site. Please read this Statement carefully to learn how we collect, process, protect or otherwise use your personal information.',
  '_GDPR2_NORMA1': 'We only collect your personal information when you provide us with such information when filling in the form or when sending a service request. Non-confidential information and data can be collected automatically through our Internet server or by using cookies. Examples of information about your use of the site include: the most visited and most viewed pages and links on our website, the number of filled forms, the time spent on the page, the most popular keywords that users lead to our site, your IP address, information collected through cookies, data your device, such as hardware settings, system activity, browser type, etc. We use the information obtained about you:',
  '_GDPR2_NORMA_STAVKA1': 'To establish the primary channel of communication with you and service delivery;',
  '_GDPR2_NORMA_STAVKA2': 'In order to improve our website and our services (we are constantly trying to improve the offer of our website based on the feedback we receive from our visitors); ',
  '_GDPR2_NORMA2': 'We do not offer third party services or products on our website. In order to protect the personal information you provide through this website, we use physical, technical, and organizational security measures. We are upgrading and testing our security technology. We restrict access to your personal data only to those employees who need to know this information in order to provide you with some benefits or services .Often, we educate our employees about the importance of data confidentiality, privacy protection and protection of your data. All data is stored in Alfa Therm database and AlfaTM servers, and we will not transfer or store the stored data to countries outside the European Union.' +
    'Your personal information is kept and protected for the duration of your business relationship with us. Your personal data you have provided for business or contact purposes is regularly deleted 12 months after you give us this information. If you want to know we own and process your personal information , or if you want to access the personal information we have about you, please contact us at alfatherm@alfatherm.com. ' +
    'You can also request information about: the purpose of data processing, the categories of personal data processed; who is still outside of our company received your personal information from us; which is the source of personal data (if you have not provided us directly); and how long we will keep the data. You have the right to correct the personal information we possess if they are incorrect. You may also, with certain exceptions, request that we delete your information or stop processing them. You may request that we stop using your personal information for direct marketing purposes By using this website, you are granting access to this Privacy Policy.',
  '_ANKETNI_LIST': 'Survey',
  '_POPUNITE_ANKETU': 'Please respond to the asked questions, so that we could improve our services.',
  '_KLIJENT': 'Client',
  '_DODATNO_PITANJE': 'If you want, you can list your remarks or suggestions for improvement of ALFA THERM d.o.o',

  '_PROJEKTIRANJE_IKONA_1': 'PROFESSIONAL STAFF',
  '_PROJEKTIRANJE_IKONA_2': 'PRACTICAL KNOWLEDGE',
  '_PROJEKTIRANJE_IKONA_3': 'COMPREHENSIVE SOLUTIONS',
  '_PROJEKTIRANJE_IKONA_4': 'FLEXIBILITY AND SPEED OF SOLUTION',
  '_PROJEKTIRANJE_IKONA_5': 'TEAMWORK',

  //PROFIL TVRTKE
  '_PROFIL_TVRTKE_NASLOV_1': 'HISTORY OF ALFA THERM',
  '_PROFIL_TVRTKE_NASLOV_2': 'SOCIAL RESPONSIBILITY',
  '_PROFIL_TVRTKE_NASLOV_3': 'YOUTH',
  '_PROFIL_TVRTKE_NASLOV_4': 'ENERGY',
  '_PROFIL_TVRTKE_PARAGRAF_1': 'Alfa Therm d.o.o. offers a range of services in the fields of heating, air conditioning, ventilation, technical plans, precision air conditioning, automatics, central control systems and other activities related to the industry.',
  '_PROFIL_TVRTKE_PARAGRAF_2': 'All of our services are designed to meet the requirements of our clients, with an emphasis on quality.',
  '_PROFIL_TVRTKE_PARAGRAF_3': 'The company was founded in 1987. In the beginning it was a family business with few employees. Expansion began in 1996 and has since recorded steady growth both in terms of revenue, and the number of employees.One of the main reasons of the companys progress are our employees who are experts in their fields and are always ongoing education and training. This is why we are able to respond to the most complex requirements.',
  '_PROFIL_TVRTKE_PARAGRAF_4': 'With constant technical education, which is the foundation of our successful business, the company invests in the organization using the software for managing and controlling processes and projects.',
  '_PROFIL_TVRTKE_PARAGRAF_5': 'Alfa Therm operates through two companies:',
  '_PROFIL_TVRTKE_PARAGRAF_6': 'Alfa Therm d.o.o. Mostar (Bosnia and Herzegovina)',
  '_PROFIL_TVRTKE_PARAGRAF_7': 'Alfa Therm d.o.o. Metković (Croatia)',
  '_PROFIL_TVRTKE_PARAGRAF_8': 'Hereby, we cover the whole territory of Bosnia and Herzegovina, and the southern part of the Croatia. We are also present in Montenegro and Kosovo.',
  '_PROFIL_TVRTKE_PARAGRAF_9': 'Responsibility to the wider community is a part of our mission.',
  '_PROFIL_TVRTKE_PARAGRAF_10': 'The motto we chose: ‘Energy and youth drive the world – treasure them’, shows in which society segments we are the most active when it comes to social responsibility.',
  '_PROFIL_TVRTKE_PARAGRAF_11': 'With scholarships and assistance to students, Alfa Therm constantly supports various cultural and sporting events, schools and universities. Engaging and training young people is one of the most effective ways of investing in the welfare and the future of the whole society.',
  '_PROFIL_TVRTKE_PARAGRAF_12': 'A youth educational project which we are especially proud of is ',
  '_PROFIL_TVRTKE_PARAGRAF_13': ' in Mostar.CTK is a place where young from the age group between 6 and 26 are provided with an opportunity to gain practical knowledge in electronics, robotics and computer science.',
  '_PROFIL_TVRTKE_PARAGRAF_14': 'Besides young people, we put our focus on energy and its utilization.',
  '_PROFIL_TVRTKE_PARAGRAF_15': 'Aware of the exploitation of resources, and increased consumption, which does not justify its rise, we offer our customers energy efficient solutions.',
  '_PROFIL_TVRTKE_CITAT_1': 'ALFA THERM IS STABLE AND RESPECTED REGIONAL COMPANY, WHICH IS A SYMBOL OF KNOWLEDGE, RESPONSIBILITY AND QUALITY.',
  '_PROFIL_TVRTKE_CITAT_2.1': 'We preserve ',
  '_PROFIL_TVRTKE_CITAT_2.2': 'energy ',
  '_PROFIL_TVRTKE_CITAT_2.3': 'and invest in ',
  '_PROFIL_TVRTKE_CITAT_2.4': 'youth.',
  '_CENTAR_TEHNICKU_KULTURU': ' ‘Center for Technical Education’ (CTK)',

  //KOMPLETNOST USLUGE
  '_KOMPLETNOST_USLUGE_PARAGRAF_1': 'With a working experience of over 30 years and our knowledge and resources, we are able to offer clients complete service in the field.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_2': 'Our experience and technical ability makes us ready to respond to all requests, no matter how complex they are. We always bring the most optimum solution.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_3': 'We approach each client individually with special dedication and attention to his needs and requests. ',
  '_KOMPLETNOST_USLUGE_PARAGRAF_4': 'We have a rich list of business references with a large number of objects with different purposes:',
  '_KOMPLETNOST_USLUGE_PARAGRAF_5': 'For all of these types of facilities we offer a complete range of services and a lifelong maintenance to ensure the comprehensiveness of solutions - from the concept, design and execution, to the service and maintenance at the end.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_6': 'Thanks to the integrity and quality of our services, clients have entrusted us with the most eminent facilities in the region.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_7': 'SOME OF THE SERVICES WE OFFER ARE:',
  '_KOMPLETNOST_USLUGE_LIST_1.1': 'Business',
  '_KOMPLETNOST_USLUGE_LIST_1.2': 'Production plants',
  '_KOMPLETNOST_USLUGE_LIST_1.3': 'Malls',
  '_KOMPLETNOST_USLUGE_LIST_1.4': 'Housing',
  '_KOMPLETNOST_USLUGE_LIST_1.5': 'Hotels and bars',
  '_KOMPLETNOST_USLUGE_LIST_1.6': 'Religious objects',
  '_KOMPLETNOST_USLUGE_LIST_1.7': 'Hospitals',
  '_KOMPLETNOST_USLUGE_LIST_1.8': 'Educational and public institutions',
  '_KOMPLETNOST_USLUGE_LIST_1.9': 'Objects for special purposes',
  '_KOMPLETNOST_USLUGE_LIST_1.10': 'Wedding Salons',
  '_KOMPLETNOST_USLUGE_LIST_1.11': 'Sports halls',
  '_KOMPLETNOST_USLUGE_LIST_1.12': 'Hydropower plants ( centrally controlled air conditioning system )',
  '_KOMPLETNOST_USLUGE_LIST_2.1': 'Heating, ventilation and air conditioning ( HVAC ) design',
  '_KOMPLETNOST_USLUGE_LIST_2.2': 'HVAC design of automated regulation and building management systems (BMS)',
  '_KOMPLETNOST_USLUGE_LIST_2.3': 'Installation of equipment for air conditioning and system start-up',
  '_KOMPLETNOST_USLUGE_LIST_2.4': 'Production of air ducts and steel part components',
  '_KOMPLETNOST_USLUGE_LIST_2.5': 'Service of installed equipment',
  '_KOMPLETNOST_USLUGE_LIST_2.6': 'Preventive maintenance',
  '_KOMPLETNOST_USLUGE_LIST_2.7': 'Energy efficiency projects',

  //ENERGETSKA EFIKASNOST
  '_ENERGETSKA_EFIKASNOST_PARAGRAF_1': 'Our company pays great attention to energy efficiency and a clean and healthy environment in which we live. Systems that work for many years can develop an increased consumption, which leads to a great increase in costs over the years. Alfa Therm in its service offers improvement of the energy system, which greatly reduces the costs of everyday work.',
  '_ENERGETSKA_EFIKASNOST_PARAGRAF_2': 'Along complete solutions for new facilities, we also offer upgrades and enhancements to existing systems. Systematic analysis which we provide to our clients offers them accurate information about the saving and cost reducing possibilities. Our report contains data about the investment size, payment period and the savings amount. The value: ‘A man’s future depends on the awareness of his environment’ is a foundation on which we build the future of our company and the environment in which we live in.',
  '_ENERGETSKA_EFIKASNOST_CITAT_1.1': 'Energy is the essence of',
  '_ENERGETSKA_EFIKASNOST_CITAT_1.2': 'life',

  //PROJEKTIRANJE
  '_PROJEKTIRANJE_NASLOV': 'ALONG TO OPTIMAL SOLUTION',
  '_PROJEKTIRANJE_PARAGRAF_1': 'Together to an optimal solution',
  '_PROJEKTIRANJE_PARAGRAF_2': 'Alfa Therm HVAC Design team has over ten engineers and certified planners that have all the necessary licenses for project design and supervision.',
  '_PROJEKTIRANJE_PARAGRAF_3': 'Our team of engineers constantly monitors the development of new technologies and works closely with various research institutions to improve existing solutions.',
  '_PROJEKTIRANJE_PARAGRAF_4': 'The whole process is overseen and led by experienced managers, who take responsibility for delivering projects according to the agreed requirements and quality standards.',
  '_PROJEKTIRANJE_PARAGRAF_5': 'We adapt solutions to customers, their desires and needs, always taking into account the reliability and optimal ratio of investment and exploitation.',
  '_PROJEKTIRANJE_PARAGRAF_6': 'All programs and tools used in our design are licensed.',
  '_PROJEKTIRANJE_PARAGRAF_7': 'ALFA THERM D.O.O. OFFERS A RANGE OF SERVICES IN THE FIELDS OF HEATING, AIR CONDITIONING, VENTILATION, TECHNICAL PLANS, PRECISION AIR CONDITIONING, AUTOMATICS, CENTRAL CONTROL SYSTEMS AND OTHER ACTIVITIES RELATED TO THE INDUSTRY.',
  '_PROJEKTIRANJE_PARAGRAF_8': 'Our engineers monitor new technological advances and trends through continuous training on the latest international standards and through professional publications, conferences, seminars, and education from world-renowned equipment manufacturers (Siemens, York, Emerson, Daikin, Danfos, etc.).',
  '_PROJEKTIRANJE_PARAGRAF_9': 'OPTIMUM INSTALLATION INVOLVES LESS INVESTMENT AND OPERATION COSTS WHILE SATISFYING DEMANDS FOR COMFORT AND PRESERVATION OF THE ENVIRONMENT',
  '_KONTAKTIRAJTE_NAS': 'Contact us',

  //BIM PROJEKTIRANJE INSTALACIJA
  '_BIM': 'BIM ',
  '_BIM_PROJEKTIRANJE_PARAGRAF_1': '(Building Information Modeling) is a smart design process based on creating models which contain information needed for better planning, design, manufacturing and building management. These are complete 3D, or 5D models which improve team coordination, lower project difficulties and save money and time.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_2': 'Because Alfa Therm is a company which always strives to improve its business and develop new services, we recognized the importance of BIM design for the architectural field. By implementing BIM we want to be more efficient and closer to our clients wishes. The world market is in need of high-quality solutions and because of this we decided to invest in the development of BIM design within our company.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_3': 'Our HVAC experts who have years of experience in a plethora of projects are now implementing BIM modeling which expands and raises the standards of our services even more.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_4': 'By using BIM modeling you can plan in advance, save on costs, and make sure that your HVAC system is functioning in the most optimal and economical way.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_5': 'The satisfaction of our clients is the best confirmation of our successful business which proves the quality of our company. If you are planning a project which demands for a BIM HVAC solution please do contact us. We will be glad to assist you in making your plan a success.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_6': 'In recent years Alfa Therm d.o.o. has been cooperating with the largest engineering design office in Europe, Swedish ',
  '_BIM_PROJEKTIRANJE_PARAGRAF_7': 'SWECO AB currently has over 14,500 employees with a 125 year long experience in engineering and is a leader in the field of most complex engineering design. As an official SWECO partner we cooperate on most demanding HVAC projects for different types of buildings.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_8': 'This cooperation has started a new business chapter for Alfa Therm. We opened a new BIM department which led to a possibility of hiring and educating young experts in the field.',
  '_BIM_PROJEKTIRANJE_CITAT_1': 'BUILDING INFORMATION MODELING',
  '_BIM_PROJEKTIRANJE_CITAT_2': 'PLAN FOR THE FUTURE!',

  //AUTOMATSKA REGULACIJA
  '_AUTOMATSKA_REGULACIJA_NASLOV_1': 'CENTRAL BUILDING MANAGEMENT SYSTEM (CENTRAL BMS)',
  '_AUTOMATSKA_REGULACIJA_NASLOV_2': 'BASIC FUNCTIONS OF BMS',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_1': 'Automated regulation ensures high quality and energy efficient system.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_2': 'Our strength is in the fact that we are able to design, program, install, put into operation, and service the most complicated systems of automated regulation (PX Siemens, Samsung Sync, Johnson Controls FX, and Johnson Controls FAC).',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_3': 'We have more than 10 years of experience in automated regulation systems on different types of objects, from smart homes to complex systems of central monitoring (CNS) management in large buildings.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_4': 'The solutions we offer our clients:',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_5': 'Innovative solutions in BMS provide clients with a significant reduction in energy costs, as well as a decrease in other expenses in the exploitation of the system with increased comfort.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_6': 'In order to simplify control and monitoring of work equipment, as well as to reduce consumption, today’s companies are increasingly using central building management system or BMS.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_7': 'BMS is a computer-based control system integrated in the object, which regulates and monitors the state of the electrical and mechanical equipment as well as equipment for air conditioning, heating, cooling, lighting, fire protection, security systems, etc.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_8': 'From an energy point of view, by far the largest part (up to 80%) of the total energy in buildings is used for air-conditioning (cooling, heating, dehumidification, ventilation) and the preparation of hot water.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_9': 'Therefore, it is profitable to invest in the elements of the control system to optimize energy consumption, simplify management and control, and increase comfort.',
  '_AUTOMATSKA_REGULACIJA_LIST_1.1': 'systems that control air conditioning, heating and cooling',
  '_AUTOMATSKA_REGULACIJA_LIST_1.2': 'energy management systems',
  '_AUTOMATSKA_REGULACIJA_LIST_1.3': 'lighting control',
  '_AUTOMATSKA_REGULACIJA_LIST_1.4': 'Building management system (BMS)',
  '_AUTOMATSKA_REGULACIJA_LIST_2.1': 'System Management',
  '_AUTOMATSKA_REGULACIJA_LIST_2.2': 'System Monitoring',
  '_AUTOMATSKA_REGULACIJA_LIST_2.3': 'Optimizing energy costs',

  //MONTAZA
  '_MONTAZA_NASLOV_1': 'INTERNAL TECHNICAL INSPECTION AS A QUALITY ASSURANCE',
  '_MONTAZA_NASLOV_2': 'WORKSHOP AND STORAGE',
  '_MONTAZA_PARAGRAF_1': 'A proper and reliable method of installation guarantees the accuracy and efficiency of the system. Therefore, the installations teams in Alfa Therm are solely made of specialized and trained staff.',
  '_MONTAZA_PARAGRAF_2': 'Regulated procedures for installation are additional guarantees of successfully completed projects.',
  '_MONTAZA_PARAGRAF_3': 'Additional support to the installations team is a workshop in which we independently produce and prepare the necessary elements for the integration of the entire system.',
  '_MONTAZA_PARAGRAF_4': 'The capacity of our storage enables access to all elements, which greatly facilitates, but also provides additional security to our clients.',
  '_MONTAZA_PARAGRAF_5': 'In order to verify all the technical characteristics of the system and the accuracy of completed assemblage works, Alfa Therm is organizing internal technical inspection so that we detect and correct possible deviations in the assemblage.',
  '_MONTAZA_PARAGRAF_6': 'To reduce the assembly deadlines on buildings, our workshop independently produces and prepares a variety of mechanical elements (ducts, distributors, girders, etc.)',
  '_MONTAZA_PARAGRAF_7': 'A rich experience of our installations team, as well as a large number of successfully finished complex objects are a guarantee of the quality of our installations.',

  //SERVIS
  '_SERVIS_PARAGRAF_1': 'The service office is available 24 hours a day, seven days a week, which proves our commitment to take care of our customers.',
  '_SERVIS_PARAGRAF_2': 'Our service center is able to quickly and efficiently detect a malfunction, and in agreement with the client repair the damage in an optimal and fast way all thanks to our internal storage of spare parts.',
  '_SERVIS_PARAGRAF_3': 'Service teams in Alfa Therm are comprised of specialized technicians, all equipped with a service vehicle and the needed sophisticated tools.',
  '_SERVIS_PARAGRAF_4': 'The teams are educated regularly, both by equipment manufacturers, as well as our internal training staff to provide fast and quality service intervention and ultimately accurate operation of equipment put into operation.',
  '_SERVIS_PARAGRAF_5': 'Respecting the stipulated work procedures, occupational safety and health, and fire protection are an imperative in our daily work.',

  //ODRZAVANJE
  '_ODRZAVANJE_PARAGRAF_1': 'Preventive maintenance is proven to be one of the best services and the best choice by our clients because of its results:',
  '_ODRZAVANJE_PARAGRAF_2': 'With technical and hygienic preventive maintenance we want to introduce a new quality of life and work in the working space and thereby reduce interventions in the heating/cooling season.',
  '_ODRZAVANJE_PARAGRAF_3': 'Our service department prepares a preventive maintenance plan so that the equipment is always maintained according to the set schedule. Service department trains the personnel and manages a permanent stock of spare parts.',
  '_ODRZAVANJE_PARAGRAF_4': 'You can rely on our service 24 hours a day.',
  '_ODRZAVANJE_LIST_1.1': 'Reduced energy consumption',
  '_ODRZAVANJE_LIST_1.2': 'Extension of equipment life up to 50%',
  '_ODRZAVANJE_LIST_1.3': 'Safe work during the season',
  '_ODRZAVANJE_LIST_1.4': 'Clean hygiene with a reduced risk of bacteria and virus transmission',
  '_ODRZAVANJE_LIST_1.5': 'A cleaner and healthier air',

  //ENERGETSKI AUDIT
  '_ALFA_THERM': 'Alfa Therm d.o.o. Mostar ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.1': 'On April the 3rd 2015 Federal Ministry of Physical Planning gave ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.2': 'licence number UPI / 03-23-2-341 / 14 SK ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.3': ', to perform energy audits and energy certification for residential and non-residential buildings with a complex technical systems.',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.1': 'Energy audit ',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.2': 'is a procedure that analyzes energy performance of the building including profitability of investments in energy saving measures. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.1': 'The energy PERFORMANCE certificate ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.2': 'is a document that establishes building energy efficiency rating, provides information on energy consumption, energy characteristics and condition of the building. The energy certificate also includes proposed measures for cost-effective improvement of the building energy performance to reduce energy consumption defined by energy audit. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_4': 'Saving energy and using renewable energy we save personal resources, and building energy efficiency rating can affect its market value. Depending on the energy consumption the facility is placed in one of the eight energy classes: A +, A, B, C, D, E, F, G.',
  '_ENERGETSKI_AUDIT_PARAGRAF_5': 'Improving the Energy efficiency rating of your building can improve quality of life to you and to your family. It can also extend the buildings lifetime, reduce energy consumption and thus the maintenance costs.',

  //REFERENCE
  '_JAVNE_USTANOVE': 'Public institutions',
  '_OBJEKTI_POSEBNE_NAMJENE': 'Buildings for special purposes',
  '_OSTALI_OBJEKTI': 'Other buildings',
  '_POSLOVNE_ZGRADE': 'Business buildings',
  '_PRODAJNI_CENTRI': 'Shopping centers',
  '_SAKRALNI_OBJEKTI': 'Sacral buildings',
  '_UGOSTITELJSKI_OBJEKTI': 'Hospitality facilities',

  '_MISLJENJE': 'Your opinion is important!',
  '_PRIMJEDBE_TEKST': 'We are trying to improve our business and raise the services quality level. Your suggestions, comments, questions and complaints are welcomed. If you are a customer, partner, supplier, associate or our  employee, please leave your comment. We want to raise the level of your satisfaction and provide you with the service you expect. Together we can do more!',
  '_PRIMJEDBE_TEKST_2': 'If you want to stay anonymous, skip the contact field',
};
