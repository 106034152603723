import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../service/translate/translate.service';

@Component({
    selector: 'at-footer',
    templateUrl: './footer.component.html',
    styles: [`
        :host {
            height:100vh;
        }
    `]
})


export class FooterComponent implements OnInit {

    public lang: string;
   
    constructor(private _translate: TranslateService) { }

    ngOnInit() {
        this.loadText();
    }

    loadText() {
        this.lang = sessionStorage.getItem("Jezik");

        if (this.lang == undefined || this.lang == null)
            this.lang = 'hr';

        this._translate.use(this.lang);
    }
}
