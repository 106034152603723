import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate2'
})
export class Truncate2Pipe implements PipeTransform {
  transform(value: string, limit = 140, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    if (value != undefined && value != null && value.length > limit)
      return `${value.substr(0, limit)}${ellipsis}`;
    return value;
  }
}
