import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../service/translate/translate.service';

declare var $: any;

@Component({
    selector: 'at-first-footer',
    templateUrl: './firstfooter.component.html'
})


export class FirstFooterComponent implements OnInit {

    public lang: string;

    constructor(private _translate: TranslateService) { }

    ngOnInit() {
        this.loadText();
    }

    loadText() {
        this.lang = sessionStorage.getItem("Jezik");

        if (this.lang == undefined || this.lang == null)
            this.lang = 'hr';

        this._translate.use(this.lang);
  }

  backToTop() {
    $('#back-to-top').tooltip('hide');
    $('body,html').animate({
      scrollTop: 0
    }, 800);
  }
}
