import { Routes } from "@angular/router";

export const appRoutes: Routes = [

  { path: 'hr/Pocetna', loadChildren: './components/home/home.module#HomeModule' },
  { path: 'en/Home', loadChildren: './components/home/home.module#HomeModule' },
  { path: 'de/Startseite', loadChildren: './components/home/home.module#HomeModule' },

  { path: 'hr/Reference', loadChildren: './components/references/reference.module#ReferenceModule' },
  { path: 'en/References', loadChildren: './components/references/reference.module#ReferenceModule' },
  { path: 'de/Referenzen', loadChildren: './components/references/reference.module#ReferenceModule' },

  { path: 'hr/Partneri', loadChildren:'./components/partners/partners.module#PartnersModule' },
  { path: 'en/Partners', loadChildren: './components/partners/partners.module#PartnersModule' },
  { path: 'de/Partners', loadChildren: './components/partners/partners.module#PartnersModule' },

  { path: 'hr/Novosti', loadChildren: './components/news/news.module#NewsModule' },
  { path: 'en/News', loadChildren: './components/news/news.module#NewsModule' },
  { path: 'de/Nachrichten', loadChildren: './components/news/news.module#NewsModule' },

  { path: 'hr/Novosti/:id', loadChildren: './components/news/details/details.module#NewsDetailsModule' },
  { path: 'en/News/:id', loadChildren: './components/news/details/details.module#NewsDetailsModule' },
  { path: 'de/Nachrichten/:id', loadChildren: './components/news/details/details.module#NewsDetailsModule' },

  { path: 'hr/Misija_i_vizija', loadChildren: './components/about-us/mission/mission.module#MissionAndVissionModule' },
  { path: 'en/Mission_and_vision', loadChildren: './components/about-us/mission/mission.module#MissionAndVissionModule' },
  { path: 'de/Mission_und_vision', loadChildren: './components/about-us/mission/mission.module#MissionAndVissionModule' },

  { path: 'hr/Natjecaji', loadChildren: './components/about-us/positions/positions.module#OpenPositionsModule' },
  { path: 'en/Open_positions', loadChildren: './components/about-us/positions/positions.module#OpenPositionsModule' },
  { path: 'de/Wettbewerbe', loadChildren: './components/about-us/positions/positions.module#OpenPositionsModule' },

  { path: 'hr/Stipendije', loadChildren: './components/about-us/scholarships/scholarship.module#ScholarshipModule' },
  { path: 'en/Scholarships', loadChildren: './components/about-us/scholarships/scholarship.module#ScholarshipModule' },
  { path: 'de/Stipendien', loadChildren: './components/about-us/scholarships/scholarship.module#ScholarshipModule' },

  { path: 'hr/Profil_tvrtke', loadChildren: './components/about-us/companyprofile/companyprofile.module#CompanyProfileModule' },
  { path: 'en/Company_profile', loadChildren: './components/about-us/companyprofile/companyprofile.module#CompanyProfileModule'  },
  { path: 'de/FirmenProfile', loadChildren: './components/about-us/companyprofile/companyprofile.module#CompanyProfileModule'  },

  { path: 'hr/Kompletnost_usluge', loadChildren: './components/about-us/servicecompletness/servicecompletness.module#CompletnessOfServicesModule' },
  { path: 'en/Completness_of_services', loadChildren: './components/about-us/servicecompletness/servicecompletness.module#CompletnessOfServicesModule' },
  { path: 'de/Service-Komplettpaket', loadChildren: './components/about-us/servicecompletness/servicecompletness.module#CompletnessOfServicesModule' },

  { path: 'hr/Energetska_efikasnost', loadChildren: './components/about-us/energyefficiency/energyefficiency.module#EnergyEfficiencyModule' },
  { path: 'en/Energy_efficiency', loadChildren: './components/about-us/energyefficiency/energyefficiency.module#EnergyEfficiencyModule' },
  { path: 'de/Energieefizienz', loadChildren: './components/about-us/energyefficiency/energyefficiency.module#EnergyEfficiencyModule' },

  { path: 'hr/Projektiranje', loadChildren: './components/services/hvacdesign/hvacdesign.module#HvacDesignModule' },
  { path: 'en/Hvac_design', loadChildren: './components/services/hvacdesign/hvacdesign.module#HvacDesignModule'  },
  { path: 'de/Projektierung', loadChildren: './components/services/hvacdesign/hvacdesign.module#HvacDesignModule'  },

  { path: 'hr/BIM_projektiranje_instalacija', loadChildren: './components/services/biminstallation/biminstallation.module#BimInstallationDesignModule' },
  { path: 'en/BIM_installation_design', loadChildren: './components/services/biminstallation/biminstallation.module#BimInstallationDesignModule' },
  { path: 'de/BIM_Installationsprojektierung', loadChildren: './components/services/biminstallation/biminstallation.module#BimInstallationDesignModule' },

  { path: 'hr/Montaza', loadChildren: './components/services/installation/installation.module#InstallationModule' },
  { path: 'en/Installation', loadChildren: './components/services/installation/installation.module#InstallationModule' },
  { path: 'de/Montage', loadChildren: './components/services/installation/installation.module#InstallationModule' },

  { path: 'hr/Automatska_regulacija', loadChildren: './components/services/automatedregulation/regulation.module#AutomatedRegulationModule' },
  { path: 'en/Automated_regulation', loadChildren: './components/services/automatedregulation/regulation.module#AutomatedRegulationModule' },
  { path: 'de/Automatische_Regulierung', loadChildren: './components/services/automatedregulation/regulation.module#AutomatedRegulationModule' },

  { path: 'hr/Servis', loadChildren: './components/services/service/service.module#ServiceModule' },
  { path: 'en/Service', loadChildren: './components/services/service/service.module#ServiceModule' },
  { path: 'de/Kundenservice', loadChildren: './components/services/service/service.module#ServiceModule' },

  { path: 'hr/Odrzavanje', loadChildren: './components/services/maintenance/maintenance.module#MaintenanceModule' },
  { path: 'en/Preventive_maintenance', loadChildren: './components/services/maintenance/maintenance.module#MaintenanceModule' },
  { path: 'de/Wartung', loadChildren: './components/services/maintenance/maintenance.module#MaintenanceModule' },

  { path: 'hr/Energetski_audit', loadChildren: './components/services/audit/audit.module#EnergyAuditModule' },
  { path: 'en/Energy_audit', loadChildren: './components/services/audit/audit.module#EnergyAuditModule' },
  { path: 'de/Der_Energieaudit', loadChildren: './components/services/audit/audit.module#EnergyAuditModule'},

  { path: 'Prijava', loadChildren: './Admin/Login/login.module#LoginModule'},

  { path: 'hr/Sto_je_ISO', loadChildren: './components/ISO/iso.module#ISOModule' },
  { path: 'en/What_is_ISO', loadChildren: './components/ISO/iso.module#ISOModule' },
  { path: 'de/Was_ist_ISO', loadChildren: './components/ISO/iso.module#ISOModule' },

  { path: 'hr/Primjedbe_i_komentari', loadChildren: './components/reviews/reviews.module#ReviewsModule' },
  { path: 'en/Comments_and_remarks', loadChildren: './components/reviews/reviews.module#ReviewsModule' },
  { path: 'de/Kommentare_und_Anmerkungen', loadChildren: './components/reviews/reviews.module#ReviewsModule' },

  { path: 'hr/Kontakt', loadChildren: './components/contact/contact.module#ContactModule' },
  { path: 'en/Contact', loadChildren: './components/contact/contact.module#ContactModule' },
  { path: 'de/Kontakt', loadChildren: './components/contact/contact.module#ContactModule' },

  { path: 'hr/Servisna_sluzba', loadChildren: './components/callcenter/callcenter.module#CallCenterModule' },
  { path: 'en/Call_center', loadChildren: './components/callcenter/callcenter.module#CallCenterModule' },
  { path: 'de/Kundendienst', loadChildren: './components/callcenter/callcenter.module#CallCenterModule' },

  { path: 'hr/Ispitivanje_zadovoljstva_klijenata', loadChildren: './components/survey/survey.module#SurveyModule' },
  { path: 'en/Customer_Satisfaction_Testing', loadChildren: './components/survey/survey.module#SurveyModule' },
  { path: 'de/Kundenzufriedenheitstest', loadChildren: './components/survey/survey.module#SurveyModule' },

  { path: '', redirectTo: 'hr/Pocetna', pathMatch: 'full' },

  { path: 'Admin', loadChildren: './Admin/admin.module#AdminModule' },
];
