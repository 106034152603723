import { InjectionToken } from '@angular/core';

// import translations
import { LANG_HR_NAME, LANG_HR_TRANS } from './lang-hr';
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang-en';
import { LANG_DE_NAME, LANG_DE_TRANS } from './lang-de';

// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
export const dictionary = {
    [LANG_DE_NAME]: LANG_DE_TRANS,
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_HR_NAME]: LANG_HR_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];
