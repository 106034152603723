import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions } from "@angular/http";
import { Path } from "../path";

@Injectable()
export class SiteService {
  readonly rootUrl = Path.URL;

  public MisijaID = 2;
  public NatjecajID = 3;
  public Stipendije = 5;
  public Profil = 6;
  public Kompletnost = 7;
  public Efikasnost = 8;

  public ProjektiranjeID = 1;
  public BIMID = 2;
  public AutomatskaRegulacijaID = 3;
  public MontazaID = 4;
  public ServisID = 5;
  public OdrzavanjeID = 6;
  public EnergetskiAudit = 7;

  constructor(private http: HttpClient) {
  }

  getAllNews(lang, page) {
    return this.http.get(this.rootUrl + '/api/News/GetNews?lang=' + lang + '&page=' + page);
  }

  getTop6(lang) {
    return this.http.get(this.rootUrl + '/api/News/GetTop6?lang=' + lang);
  }

  getMaxPage(lang, page) {
    return this.http.get(this.rootUrl + '/api/News/GetMaxNews?lang=' + lang + '&page=' + page);
  }

  getNewsById(id, lang) {
    return this.http.get(this.rootUrl + '/api/News/GetNewsById?id=' + id+'&lang='+lang);
  }

  getReferences(lang) {
    return this.http.get(this.rootUrl + '/api/Reference/GetReferences?lang=' + lang);
  }

  getReferencesByType(type, lang) {
    return this.http.get(this.rootUrl + '/api/Reference/GetReferencesByType?type='+type+'&lang=' + lang);
  }

  getpriorityReferences(lang) {
    return this.http.get(this.rootUrl + '/api/Reference/GetPriorityReferences?lang=' + lang);
  }

  getReferenceTypes(lang) {
    return this.http.get(this.rootUrl + '/api/ReferenceType/GetAll?lang=' + lang);
  }

  getImageThumb(id) {
    return this.http.get(this.rootUrl + '/api/Image/GetThumb?id=' + id);
  }

  getImage(id) {
    return this.http.get(this.rootUrl + '/api/Image/GetImage?id=' + id);
  }

  getPartners() {
    return this.http.get(this.rootUrl + '/api/Partner/GetPartners');
  }

  getHomeContent(lang) {
    return this.http.get(this.rootUrl + '/api/Home/GetContent?lang=' + lang);
  }

  getAboutUsContent(lang) {
    return this.http.get(this.rootUrl + '/api/AboutUs/GetAll?lang=' + lang);
  }

  getAboutUsItem(lang, id) {
    return this.http.get(this.rootUrl + '/api/AboutUs/GetAboutUsItem?lang=' + lang + '&id=' + id);
  }

  getActivitiesContent(lang) {
    return this.http.get(this.rootUrl + '/api/Activity/GetAll?lang=' + lang);
  }

  getActivityItem(lang, id) {
    return this.http.get(this.rootUrl + '/api/Activity/GetActivityById?lang=' + lang + '&id=' + id);
  }

  getISO(lang) {
    return this.http.get(this.rootUrl + '/api/ISO/Get?lang=' + lang);
  }

  getTop3AboutUs(lang) {
    return this.http.get(this.rootUrl + '/api/AboutUs/GetTop3?lang=' + lang);
  }

  sendReview(ime, prezime, email, telefon, tvrtka, adresa, komentar) {
    var data = ({
      "Ime": ime, "Prezime": prezime, "Email": email, "Telefon": telefon,
      "Tvrtka": tvrtka, "Adresa": adresa, "Komentar": komentar
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Settings/SendReview', data, { headers: requestHeader });
  }

  sendContact(ime, email, telefon, komentar) {
    var data = ({
      "Ime": ime, "Email": email, "Telefon": telefon, "Komentar": komentar
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Settings/SendContact', data, { headers: requestHeader });
  }

  sendPosition(ime, email, telefon, poruka, cv, ext) {
    var data = ({
      "Ime": ime, "Email": email, "Telefon": telefon, "Poruka": poruka, "Zivotopis":cv, "Ext":ext
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Settings/SendPosition', data, { headers: requestHeader });
  }

  sendService(ime, prezime, telefon, mobitel, tvrtka, adresa, email, opis) {
    var data = ({
      "Ime": ime, "Prezime": prezime, "Email": email, "Telefon": telefon, "Mobitel": mobitel, "Tvrtka": tvrtka, "Adresa": adresa, "Komentar": opis
    });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Settings/SendService', data, { headers: requestHeader });
  }

  getQuestions(lang) {
    return this.http.get(this.rootUrl + '/api/Question/GetAll?lang=' + lang);
  }

  getAnswers() {
    return this.http.get(this.rootUrl + '/api/Question/GetAnswers');
  }

  addSurvey(klijent, komentar, answers) {
    var data = ({ "Customer": klijent, "Note": komentar });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Survey/Add', data, { headers: requestHeader });
  }

  sendSurvey(customer) {
    return this.http.get(this.rootUrl + '/api/Survey/SendSurvey?customer='+customer);
  }

  addSurveyAnswers(questionId, answer) {
    var data = ({ "QuestionId": questionId, "AnswerId": answer });
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.rootUrl + '/api/Survey/AddAnswers', data, { headers: requestHeader });
  }
}
