import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions } from "@angular/http";
@Injectable()
export class LanguageService {

  changeIcon(lang) {
    var hr = document.getElementById("hr");
    var en = document.getElementById("en");
    var de = document.getElementById("de");

    var hr_li = document.getElementById("hr-li");
    var en_li = document.getElementById("en-li");
    var de_li = document.getElementById("de-li");

    if (lang == "hr") {
      hr.classList.remove("inactive");

      en.classList.add("inactive");
      de.classList.add("inactive");

      hr_li.classList.add("inactive");
      en_li.classList.remove("inactive");
      de_li.classList.remove("inactive");
    }

    else if (lang == "en") {
      en.classList.remove("inactive");

      hr.classList.add("inactive");
      de.classList.add("inactive");

      en_li.classList.add("inactive");
      hr_li.classList.remove("inactive");
      de_li.classList.remove("inactive");
    }

    else if (lang == "de") {
      de.classList.remove("inactive");

      hr.classList.add("inactive");
      en.classList.add("inactive");

      de_li.classList.add("inactive");
      en_li.classList.remove("inactive");
      hr_li.classList.remove("inactive");
    }
  }
}
