import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LightboxModule } from 'angular2-lightbox';
import { CommonModule } from '@angular/common';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ImageCropperModule } from "ng2-img-cropper/index";
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HeaderComponent } from './components/header/header.component';
import { FirstFooterComponent } from './components/firstfooter/firstfooter.component';
import { FooterComponent } from './components/footer/footer.component';

import { TranslatePipe } from './service/translate/translate.pipe';
import { TruncatePipe } from './service/truncate.pipe';
import { SafeHtmlPipe } from './service/safeHtml.pipe';
import { Truncate2Pipe } from './service/truncate2.pipe';

import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    TranslatePipe,
    TruncatePipe,
    Truncate2Pipe,
    SafeHtmlPipe,
    FirstFooterComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlashMessagesModule.forRoot(),
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDateTimePickerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LightboxModule,
    NgxSpinnerModule
  ],
  exports: [HeaderComponent,
    TranslatePipe,
    TruncatePipe,
    Truncate2Pipe,
    SafeHtmlPipe,
    FirstFooterComponent,
    FooterComponent,
    FlashMessagesModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDateTimePickerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSpinnerModule],
})

export class SharedModule { }
