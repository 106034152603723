export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
  //ERRORS
  '_ERROR': 'Ein Fehler ist aufgetreten. Bitte versuche es erneut!',
  '_ERROR_IMAGE': 'Bitte geben Sie das Hauptbild ein!',
  '_USPJESNO_OBRISANO': 'Sie haben Daten erfolgreich gelöscht!',
  '_OBAVEZNA_POLJA': 'Sie müssen alle erforderlichen Informationen für eine bestimmte Sprache eingeben!',
  '_USPJESNO_DODANA_NOVOST': 'Sie haben erfolgreich neue Daten hinzugefügt!',
  '_USPJESNO_IZMJENJENA_NOVOST': 'Sie haben die Nachrichten erfolgreich aktualisiert!',
  '_POLJA_OBAVEZNA': 'Alle Felder sind erforderlich!',
  '_USPJESNO_DODAN_TIP_REFERENCE': 'Sie haben erfolgreich einen neuen Referenztyp hinzugefügt!',
  '_NIJE_MOGUCE_OBRISATI': 'Die Daten werden verwendet und können daher nicht gelöscht werden!',
  '_USPJESNO_IZMJENJEN_TIP_REFERENCE': 'Sie haben den Referenztyp erfolgreich aktualisiert!',
  '_USPJESNO_DODATA_REFERENCA': 'Sie haben erfolgreich einen neuen Referenz hinzugefügt!',
  '_USPJESNO_IZMJENJENA_REFERENCA': 'Sie haben die Referenz erfolgreich aktualisiert!',
  '_USPJESNO_DODAN_PARTNER': 'Sie haben erfolgreich einen neuen Partner hinzugefügt!',
  '_USPJESNO_IZMJENJEN_PARTNER': 'Sie haben einen Partner erfolgreich aktualisiert!',
  '_USPJESNO_IZMJENJEN_SADRZAJ': 'Sie haben den Inhalt erfolgreich aktualisiert!',
  '_USPJESNO_DODAN_SADRZAJ': 'Sie haben erfolgreich neuen Inhalt hinzugefügt!',
  '_NETACNI_PODACI': 'Sie haben keine korrekten Benutzerdaten eingegeben!',
  '_DODAJ_PITANJA': 'Fragen hinzufügen',
  '_UNESI_TEKST_PITANJA': 'Frage eingeben',
  '_EMAIL_ANKETA': 'Email Umfrage',
  '_EMAIL_PRIMJEDBE': 'Email Bewertungen',
  '_EMAIL_NATJECAJI': 'Email Wettbewerbe',
  '_EMAIL_KONTAKT': 'Email Kontakt',
  '_EMAIL_SERVIS': 'Email Bedienung',
  '_LOZINKA_VALIDACIJA': 'Das Passwort muss mindestens 8 Zeichen lang sein!',
  '_KORISNICKO_IME': 'Nutzername',
  '_UNESI_KORISNICKO_IME': 'Benutzername eingeben',
  '_LOZINKA': 'Passwort',
  '_UNESI_LOZINKU': 'Passwort eingeben',
  '_USPJESNA_PRIJAVA': 'Sie haben sich erfolgreich für den Wettbewerb beworben!',
  '_JEZIK': 'Sprache',
  '_SLAZEM_SE_SA_PRAVILIMA': 'Ich stimme den Datenschutzbestimmungen zu',
  '_DATUM': 'Datum',

  //ADMIN
  '_DODAJ_NOVOST': 'Nachrichten hinzufügen',
  '_NASLOV': 'Titel',
  '_TEKST': 'Text',
  '_AKCIJE': 'Aktionen',
  '_UREDI': 'Bearbeiten',
  '_OBRISI': 'Löschen',
  '_HRVATSKI_JEZIK': 'Kroatische Sprache',
  '_ENGLESKI_JEZIK': 'Englische Sprache',
  '_NJEMACKI_JEZIK': 'Deutsche Sprache',
  '_GALERIJA': 'Galerie:',
  '_GLAVNA_FOTOGRAFIJA': 'Primärbild:',
  '_DODAJ_FOTOGRAFIJE': 'Füge Bilder hinzu',
  '_NAZAD': 'Zurück',
  '_SPREMI': 'Einreichen',
  '_NASTAVI': 'Speichern & fortfahren',
  '_IZREZI': 'Ernte',
  '_UNESI_NASLOV': 'Titel eingeben',
  '_DODAJ_REFERENCU': 'Füge Referenz hinzu',
  '_NAZIV': 'Name',
  '_UNESI_NAZIV': 'Name eingeben',
  '_DODAJ_TIP_REFERENCE': 'Referenztyp hinzufügen',
  '_ODBACI': 'Stornieren',
  '_FOTOGRAFIJA': 'Fotografie',
  '_UNESI_LOKACIJU': 'Ort eingeben',
  '_PRIORITET': 'Priorität',
  '_WEBSITE': 'Webseite',
  '_UNESI_WEBSITE': 'Webseite eingeben',
  '_DODAJ_PARTNERA': 'Einen Partner hinzufügen',
  '_DODAJ_SADRZAJ': 'Inhalt hinzufügen',
  '_UNESI_TEKST': 'Text eingeben',
  '_MOBITEL': 'Handy',
  '_OPIS_KVARA': 'Beschreibung',
  '_PRIJAVI_KVAR': 'Bericht',
  '_USPJESNO_OSTAVLJEN_KOMENTAR': 'Sie haben erfolgreich einen Kommentar für ALFA THERM d.o.o abgegeben.',
  '_OZNACITE_CHECKBOX': 'Bevor Sie Ihre persönlichen Daten übermitteln, müssen Sie der Datenschutzerklärung zustimmen.',
  '_UNESITE_KOMENTAR': 'Bevor Sie eine Überprüfung absenden, müssen Sie einen Kommentar eingeben.',
  '_ANKETA': 'Umfrage',
  '_POSTAVKE': 'Einstellungen',
  '_LOKACIJA': 'Standort',
  '_OPIS': 'Hinweis',
  '_TIP_REFERENCE': 'Referenztyp',

  '_USPJESNO_POSLANA_ANKETA': 'Sie haben die Umfrage erfolgreich abgeschlossen.',
  '_POLJE_KLIJENT_OBAVEZNO': 'Bevor Sie eine Umfrage absenden, müssen Sie das Kundenfeld ausfüllen.',
  '_POPUNITE_SVA_PITANJA': 'Bevor Sie eine Umfrage absenden, müssen Sie alle Fragen der Umfrage beantworten.',

  '_USPJESNO_POSLANA_PORUKA': 'Deine Nachricht ist angekommen. Das Team von Alfa Therm wird Ihnen so schnell wie möglich antworten.',
  '_USPJESNO_PRIJAVLJEN_KVAR': 'Ihre Störung wurde empfangen.',

  //RUTE
  'hr/Pocetna': 'de/Startseite',
  'en/Home': 'de/Startseite',
  'de/Startseite': 'de/Startseite',

  'hr/Reference': 'de/Referenzen',
  'en/References': 'de/Referenzen',
  'de/Referenzen': 'de/Referenzen',

  'hr/Partneri': 'de/Partners',
  'en/Partners': 'de/Partners',
  'de/Partners': 'de/Partners',

  'hr/Novosti': 'de/Nachrichten',
  'en/News': 'de/Nachrichten',
  'de/Nachrichten': 'de/Nachrichten',
  
  'hr/Misija_i_vizija': 'de/Mission_und_vision',
  'en/Mission_and_vision': 'de/Mission_und_vision',
  'de/Mission_und_vision': 'de/Mission_und_vision',

  'hr/Natjecaji': 'de/Wettbewerbe',
  'en/Open_positions': 'de/Wettbewerbe',
  'de/Wettbewerbe': 'de/Wettbewerbe',

  'hr/Stipendije': 'de/Stipendien',
  'en/Scholarships': 'de/Stipendien',
  'de/Stipendien': 'de/Stipendien',

  'hr/Profil_tvrtke': 'de/Firmenprofile',
  'en/Company_profile': 'de/Firmenprofile',
  'de/Firmenprofile': 'de/Firmenprofile',

  'hr/Kompletnost_usluge': 'de/Service-komplettpaket',
  'en/Completness_of_services': 'de/Service-komplettpaket',
  'de/Service-komplettpaket': 'de/Service-komplettpaket',

  'hr/Energetska_efikasnost': 'de/Energieefizienz',
  'en/Energy_efficiency': 'de/Energieefizienz',
  'de/Energieefizienz': 'de/Energieefizienz',

  'hr/Projektiranje': 'de/Projektierung',
  'en/Hvac_design': 'de/Projektierung',
  'de/Projektierung': 'de/Projektierung',

  'hr/BIM_projektiranje_instalacija': 'de/BIM_Installationsprojektierung',
  'en/BIM_installation_design': 'de/BIM_Installationsprojektierung',
  'de/BIM_Installationsprojektierung': 'de/BIM_Installationsprojektierung',

  'hr/Montaza': 'de/Montage',
  'en/Installation': 'de/Montage',
  'de/Montage': 'de/Montage',

  'hr/Automatska_regulacija': 'de/Automatische_Regulierung',
  'en/Automated_regulation': 'de/Automatische_Regulierung',
  'de/Automatische_Regulierung': 'de/Automatische_Regulierung',

  'hr/Servis': 'de/Kundenservice',
  'en/Service': 'de/Kundenservice',
  'de/Kundenservice': 'de/Kundenservice',

  'hr/Odrzavanje': 'de/Wartung',
  'en/Preventive_maintenance': 'de/Wartung',
  'de/Wartung': 'de/Wartung',

  'hr/Energetski_audit': 'de/Der_Energieaudit',
  'en/Energy_audit': 'de/Der_Energieaudit',
  'de/Der_Energieaudit': 'de/Der_Energieaudit',

  'hr/Sto_je_ISO': 'de/Was_ist_ISO',
  'en/What_is_ISO': 'de/Was_ist_ISO',
  'de/Was_ist_ISO': 'de/Was_ist_ISO',

  'hr/Primjedbe_i_komentari': 'de/Kommentare_und_Anmerkungen',
  'en/Comments_and_remarks': 'de/Kommentare_und_Anmerkungen',
  'de/Kommentare_und_Anmerkungen': 'de/Kommentare_und_Anmerkungen',

  'hr/Kontakt': 'de/Kontakt',
  'en/Contact': 'de/Kontakt',
  'de/Kontakt': 'de/Kontakt',

  'hr/Servisna_sluzba': 'de/Kundendienst',
  'en/Call_center': 'de/Kundendienst',
  'de/Kundendienst': 'de/Kundendienst',

  'hr/Ispitivanje_zadovoljstva_klijenata': 'de/Kundenzufriedenheitstest',
  'en/Customer_Satisfaction_Testing': 'de/Kundenzufriedenheitstest',
  'de/Kundenzufriedenheitstest': 'de/Kundenzufriedenheitstest',

  '_PROJEKTIRANJE_IKONA_1': 'BERUFSPERSONAL',
  '_PROJEKTIRANJE_IKONA_2': 'PRAKTISCHES WISSEN',
  '_PROJEKTIRANJE_IKONA_3': 'UMFASSENDE LÖSUNGEN',
  '_PROJEKTIRANJE_IKONA_4': 'FLEXIBILITÄT UND LÖSUNGSGESCHWINDIGKEIT',
  '_PROJEKTIRANJE_IKONA_5': 'ZUSAMMENSPIEL',
  '_PRIHVATI': 'Akzeptieren',
  '_RECAPTCHA': 'Verifizierung fehlgeschlagen!',
  '_GDPR_NORMA': 'Alfa Therm d.o.o ist Inhaber von www.alfatherm.com. Wir haben diese Datenschutzerklärung erstellt, um zu zeigen, dass uns die Privatsphäre unserer Nutzer am Herzen liegt, und um sie darüber zu informieren, wie persönliche Informationen auf dieser Website erfasst und verarbeitet werden.Bitte lesen Sie diese Erklärung sorgfältig durch, um zu erfahren, wie wir Ihre personenbezogenen Daten erfassen, verarbeiten, schützen oder anderweitig verwenden.',
  '_GDPR2_NORMA1': 'Wir erfassen Ihre personenbezogenen Daten nur, wenn Sie uns diese Informationen beim Ausfüllen des Formulars oder beim Senden einer Serviceanfrage zur Verfügung stellen. Nicht vertrauliche Informationen und Daten können automatisch über unseren Internet-Server oder mithilfe von Cookies erfasst werden. Beispiele für Informationen über Ihre Nutzung der Website sind: die am häufigsten besuchten und am häufigsten aufgerufenen Seiten und Links auf unserer Website, die Anzahl der ausgefüllten Formulare, die auf der Seite verbrachte Zeit, die beliebtesten Keywords, die Nutzer auf unsere Website führen, Ihre IP-Adresse Adresse, durch Cookies gesammelte Informationen, Daten Ihres Geräts, wie Hardwareeinstellungen, Systemaktivität, Browsertyp usw. Wir verwenden die von Ihnen erhaltenen Informationen:',
  '_GDPR2_NORMA_STAVKA1': 'Einrichtung des primären Kommunikationskanals mit Ihnen und der Servicebereitstellung;',
  '_GDPR2_NORMA_STAVKA2': 'Um unsere Website und unsere Dienstleistungen zu verbessern (wir sind ständig bemüht, das Angebot unserer Website aufgrund der Rückmeldungen unserer Besucher zu verbessern); ',
  '_GDPR2_NORMA2': 'Wir bieten keine Dienstleistungen oder Produkte von Dritten auf unserer Website an. Zum Schutz der persönlichen Informationen, die Sie über diese Website bereitstellen, verwenden wir physische, technische und organisatorische Sicherheitsmaßnahmen. Wir aktualisieren und testen unsere Sicherheitstechnologie. Wir beschränken den Zugriff auf Ihre personenbezogenen Daten nur auf Mitarbeiter, die diese Informationen benötigen, um Ihnen einige Vorteile oder Dienstleistungen bieten zu können. Häufig informieren wir unsere Mitarbeiter über die Bedeutung des Datenschutzes, den Schutz der Privatsphäre und den Schutz Ihrer Daten. Alle Daten werden in der Alfa Therm-Datenbank und auf AlfaTM-Servern gespeichert. Wir werden die gespeicherten Daten nicht in Länder außerhalb der Europäischen Union übertragen oder speichern.' +
    'Ihre persönlichen Daten werden für die Dauer Ihrer Geschäftsbeziehung mit uns aufbewahrt und geschützt. Ihre personenbezogenen Daten, die Sie zu Geschäfts- oder Kontaktzwecken angegeben haben, werden regelmäßig 12 Monate, nachdem Sie uns diese Informationen gegeben haben, gelöscht. Wenn Sie wissen möchten, dass wir Ihre personenbezogenen Daten besitzen und verarbeiten oder wenn Sie auf die persönlichen Daten zugreifen möchten, die wir über Sie haben, kontaktieren Sie uns bitte unter alfatherm@alfatherm.com. ' +
    'Sie können auch Informationen anfordern über: den Zweck der Datenverarbeitung, die Kategorien der verarbeiteten personenbezogenen Daten; Wer sich noch außerhalb unseres Unternehmens befindet, hat Ihre persönlichen Daten von uns erhalten. welches die Quelle der persönlichen Daten ist (wenn Sie uns nicht direkt zur Verfügung gestellt haben); und wie lange werden wir die Daten aufbewahren. Sie haben das Recht, Ihre persönlichen Daten zu korrigieren, wenn sie falsch sind. Mit bestimmten Ausnahmen können Sie auch verlangen, dass wir Ihre Informationen löschen oder deren Verarbeitung einstellen. Sie können verlangen, dass wir Ihre persönlichen Daten nicht mehr für Direktmarketingzwecke verwenden. Durch die Nutzung dieser Website gewähren Sie Zugriff auf diese Datenschutzrichtlinie.',
  '_ANKETNI_LIST': 'Umfrage',
  '_POPUNITE_ANKETU': 'Bitte antworten Sie auf die gestellten Fragen, damit wir unsere Dienstleistungen verbessern können.',
  '_KLIJENT': 'Klient',
  '_DODATNO_PITANJE': 'Wenn Sie möchten, können Sie Ihre Anmerkungen oder Verbesserungsvorschläge zu ALFA THERM d.o.o auflisten',

  //HEADER
  '_ONAMA': 'Über uns',
  '_ONAMA2':'UberUns',
  '_DJELATNOSTI': 'Tätigkeiten',
  '_REFERENCE': 'Referenzen',
  '_PARTNERI': 'Partners',
  '_NOVOSTI': 'Nachrichten',
  '_KONTAKT': 'Kontakt',
  '_SERVISNA_SLUZBA': 'Kundendienst',
  '_SERVISNA_SLUZBA2': 'Kundendienst',
  '_MISIJA_VIZIJA': 'Mission und vision',
  '_NATJECAJI': 'Wettbewerbe',
  '_STIPENDIJE': 'Stipendien',
  '_PROFIL_TVRTKE': 'FirmenProfile',
  '_KOMPLETNOST_USLUGE': 'Service-Komplettpaket',
  '_ENERGETSKA_EFIKASNOST': 'Energieefizienz',
  '_PROJEKTIRANJE': 'Projektierung',
  '_BIM_PROJEKTIRANJE_INSTALACIJA': 'BIM Installationsprojektierung',
  '_AUTOMATSKA_REGULACIJA': 'Automatische Regulierung',
  '_MONTAZA': 'Montage',
  '_SERVIS': 'Kundenservice',
  '_ODRZAVANJE': 'Wartung',
  '_ENERGETSKI_AUDIT': 'Der Energieaudit',
  '_POCETNA': 'Startseite',
  '_UPRAVLJANJE_KVALITETOM': 'Qualitätsmanagement',
  '_STA_JE_ISO': 'Was ist ISO?',
  '_ISPITIVANJE_ZADOVOLJSTVA_KLIJENATA': 'Kundenzufriedenheitstest',
  '_PRIMJEDBE_KOMENTARI': 'Kommentare und Anmerkungen',

  //HOME
  '_CENTAR_ZA_TERMOENERGETSKA_RJESENJA': 'ZENTRUM FÜR THERMO ENERGETISCHE LÖSUNGEN',
  '_PROCITAJTE_VISE': 'Lesen sie mehr',
  '_PROFIL_TVRTKE_OPIS': 'Die Firma Alfa Therm d.o.o. bietet ihren Kunden eine Reihe von Dienstleistungen aus dem Bereich der Beheizung, Klimatisierung, Belüftung, der technischen Pläne, präzisen Klimatisierung, Automatisierung, zentralen Überwachungssysteme sowie andere dazugehörige Dienstleistungen.',
  '_KOMPLETNOST_USLUGE_OPIS': 'Mit über 30 Jahren Berufserfahrung, haben wir die Möglichkeit unseren Kunden durch unser Wissen und unsere Ressourcen ein Service-Komplettpaket anzubieten, nach dem Model „SCHLÜSSELFERTIG“',
  '_ENERGETSKA_EFIKASNOST_OPIS': 'Unsere Firma legt viel Wert auf Energieeffizienz, sowie auf eine reine und gesunde Umwelt. Systeme die jahrelang in Betrieb sind, können zusätzliche Ressourcen verbrauchen.',
  '_NOVOST_1': 'WELTFRAUENTAG',
  '_NOVOST_2': 'INDUSTRIEPRAXIS 2017',
  '_NOVOST_3': 'KALENDER 2017',
  '_NOVOST_4': 'WIR HABEN UNS VOM JAHR 2016 VERABSCHIEDET',
  '_NOVOST_5': 'NEUE ZWEIGSTELLE IN DUBROVNIK',
  '_NOVOST_6': 'ALFA THERM TEAM BUILDING 2016',
  '_DATUM_1': 'Dienstag, 14. März 2017',
  '_DATUM_2': ' Dienstag, 28. Februar 2017',
  '_DATUM_3': 'Dienstag, 18. Oktober 2016',
  '_NOVOST_1_OPIS': 'Firma Alfa Therm veranstaltet jedes eine Gelegenheitsfeier anlässlich des Internationen Frauentags. In diesem Jahr haben unsere Kolleginnen entschieden, diesen Tag zusammen mit ihren Kollegen mit einem kleinen Snack und Beisammensein zu feiern. Die Kollegen haben sich um die Blumen und der Firmenvorstand um ein Überraschungsgeschenk gekümmert. Wir sind uns der Tatsache bewusst, dass die Frauenrechte immer noch im großen Ausmaße eingeschränkt werden, sowohl in der Welt als auch in unserem Land, und das noch viel getan werden muss, um die Gleichberechtigung zu erreichen. Auf diesem Wege wünschen wir allen unseren Mitarbeiterinnen frohen Weltfrauentag!',
  '_NOVOST_2_OPIS': 'Der zweite Industriebesuch von Studenten wurde an diesem Dienstag in den Räumlichkeiten von Alfa Therm organisiert. Die gute Zusammenarbeit mit der Maschinenbaufakultät in Mostar setzen wir fort, um den theoretischen an der Fakultät erworbenen Teil mit der wirklichen Praxis am Arbeitsplatz zu verbinden. In diesem Jahr stieg die Zahl der interessierten Studenten an, was ein ausgezeichnetes Anzeichen ist, dass wir uns auf dem guten Wege befinden. Den Studenten im dritten Studienjahr wurde das Unternehmen Alfa Therm vorgestellt, um eine Einsicht in die Arbeitsweise und unsere Tätigkeiten zu bekommen. Es wurde ihnen die Möglichkeit gegeben, die Initiative zu zeigen und sich für das Sommerpraktikum bei Alfa Therm zu bewerben. Den Studenten im fünften Studienjahr wurden die Projektaufgaben präsentiert, die sie zum Thema ihrer Diplomarbeit wählen können. Besonders sind wir auf die Tatsache stolz, und dies möchten wir hervorheben, dass im Programm diesjährigen Besuchs eine große Rolle unsere Studenten/Mitarbeiter gespielt haben, die gerade auf diese Weise, durch das studienbegleitende Praktikum die Möglichkeit bekamen, die erste Arbeitserfahrung zu sammeln und eigene Kenntnisse zu vertiefen. Sie haben ihre Geschichte den Kollegen erzählt und die Vorteile der dualen Ausbildung vorgestellt, die immer mehr an Bedeutung gewinnt.',
  '_NOVOST_3_OPIS': 'In diesem Jahr feiert Fa. Alfa Therm ihr 30. Gründungsjubiläum. Dieses für uns bedeutende Jubiläum wird zum Anlass, zahlreiche Veranstaltungen zu organisieren, mit welchen wir die erfolgreiche Tätigkeit in vorigen Jahren feiern möchten. Dreißig Jahre werden üblich als Perlenjubiläum gefeiert und daher ist die Perlmuschel auf dem Bild diesjährigen Kalenders dargestellt. Perlen, diese einzigartigen Naturwunder, entstehen durch langjährige Arbeit einer Muschel, die die feinen, in das Innere zufällig geratenen Sandkörner mit Perlmutt beschichtet. Je größer der Fremdkörper in der Muschel, desto länger ist der Entstehungsprozess einer Perle. Nach jahrelanger Mühe bildet sich die perfekte Perlenform von großem Wert aus. Genauso braucht man im Leben für die wertvollen Sachen viel Mühe und Engagement, sowie natürlich Zeit, um einen Erfolg zu erreichen. Von Alfa Therm kann man durchaus sagen, dass sie durch diese 30 Jahre der Gestaltung einen großen Wert erreicht hat.',
  '_NOVOST_4_OPIS': 'Nun können wir von einem durchaus erfolgreichen Vorjahr für Alfa Therm sprechen. Wir möchten uns vor allem bei unseren Kunden und Mitarbeitern für die erfolgreiche Zusammenarbeit und zahlreiche realisierte Geschäfte bedanken. Alfa Therm entwickelt sich konstant und wächst. In Übereinstimmung mit unserer Mission möchten wir unsere Geschäftstätigkeit fördern, in unsere Mitarbeiter investieren, die Dienstleistungsqualität steigern und innovative Lösungen erstellen, alle mit dem Ziel, das höchste Nutzenniveau für unsere Kunden zu erreichen. Die gesellschaftliche Verantwortung ist ein sehr wichtiges Segment, an dem wir insistieren. Durch eigene Taten möchten wir ein Beispiel auch anderen Geschäftssubjekten und der breiten Öffentlichkeit sein, um gemeinsam ein besseres Umfeld im eigenen Land zu schaffen. Ein glückliches und erfolgreiches Neujahr wünschen euch Alfa Therm Mitarbeiter!',
  '_NOVOST_5_OPIS': 'Alfa Therm übt  ihre Geschäftstätigkeit über zwei Schwesterfirmen aus: Alfa Therm BiH und Alfa Therm Kroatien. Zusätzlich zum bestehenden Büro in Metković, entstand der Bedarf nach dem Eröffnen einer neuen Zweigestelle in der Dubrovnik-Region. Wir sind stolz auf die Tatsache, dass unsere in die Qualität und Dienstleistungsentwicklung investierten Bemühungen von unseren zahlreichen Kunden in Kroatien erkannt wurden, die uns ihr Vertrauen geschenkt haben. Neue Zweigstelle in Mlini bei Dubrovnik, genauer im Ort Srebrno, ist bereits einige Monate aktiv, stellt einen neuen Schritt für Alfa Therm dar und ist ein Indikator des erfolgreichen Fortschritts und der Verwirklichung von gesetzten Zielen.',
  '_NOVOST_6_OPIS': 'Alfa Therm organisiert jedes Jahr das traditionelle Teambuilding mit dem Ziel, den Gemeinschaftsgeist zu erhalten sowie gute zwischenmenschliche Beziehungen zu bauen. Da die Zahl der Beschäftigten ständig wächst, trägt das Beisammensein bedeutend dieser Kollektivität bei. Dieses Jahr fand das Teambuilding im Sportzentrum in Međugorje statt. Wir hatten herrliches Wetter, so dass der Tag mit zahlreichen Sportaktivitäten und Wettkampfenergie gefüllt werden konnte. Die Tagesgewinner wurden bereits traditionell bekanntgegeben und die Preise für den besten Sportler und die beste Mannschaft verliehen. Das Teambuilding endete mit einem durch Musik begleiteten Abendessen.',

  //FOOTER
  '_BOSNA_HERCEGOVINA': 'BOSNIEN UND HERZEGOWINA',
  '_HRVATSKA': 'KROATIEN',
  '_DODATNE_INFORMACIJE': 'Weitere Informationen',
  '_CERTIFIKAT': 'ZERTIFIKAT ISO 9001',
  '_COPYRIGHT': 'Alle Rechte vorbehalten',

  //MISSION
  '_MISIJA_PARAGRAF_1': 'Alfa Therm ist ein Zentrum für Wärmeenergielösungen, fokussiert auf Investitionen wie Einkaufszentren, Industrieobjekte, Krankenhäuser, Hotels, sakrale Objekte, spezialisiert Objekte, Wohnkomplexe… für die wir komplette Projektierungs-, Montage-, Instandhaltungs- und Serviceleistungen im Bereich der Heizung, Lüftung und Klimatisierung anbieten, unter Berücksichtigung der Energieeffizienz und des Umweltschutzes.',
  '_MISIJA_PARAGRAF_2': 'Unsere Prioritäten sind: ',
  '_MISIJA_PARAGRAF_2.1': 'verantwortungsvolle Erfüllung von Vertragspflichten',
  '_MISIJA_PARAGRAF_2.2': 'Systemzuverlässigkeit unter Anwendung von Spitzentechnologien',
  '_MISIJA_PARAGRAF_2.3': 'Kundenzufriedenstellung mit Komfortsicherung',
  '_MISIJA_PARAGRAF_2.4': 'Bestes Verhältnis von Preis, Qualität und Nutzungskosten.',
  '_MISIJA_PARAGRAF_3': 'Alfa Therm ist auf dem ganzen Gebiet von Bosnien und Herzegowina und in Dalmatien tätig.',
  '_MISIJA_PARAGRAF_4': 'Zusätzlich zur Qualitätssteigerung und Zuverlässigkeit von KHK-Installationen, bieten wir Projektierungs- und Ausführungsleistungen von kompletten Systemen der automatischen Regulierung, sowie Hydroinstallationen im Gebäudebau und stabile Feuerschutzsysteme an. Mit der Steigerung des Kompetenzniveaus des Personals durch Schulungen, Zertifizierung, Engineering sowie Entwicklung der automatischen Regulierung sind wir bestrebt, die Marktbedürfnisse zu befriedigen und den Trends zu folgen. Wir entwickeln aktiv und bieten die Projektierungsleistungen auf dem ausländischen Markt an.',
  '_MISIJA_PARAGRAF_5': 'Die Priorität bei der Beschaffung ist die Erhaltung einer qualitativen Position bei Lieferanten.  Die Entwicklung der Distribution ist auf der Förderung von Beziehungen zu den bestehenden Kooperanten und anderen Installationsunternehmen gestützt.',
  '_MISIJA_PARAGRAF_6': 'Das Ziel von Alfa Therm ist die Pflege eines positiven Arbeitsumfelds, wo ein wechselseitiges Vertrauen und  Respekt herrschen und wo alle Mitarbeiter das Sicherheits- und Zugehörigkeitsgefühl haben. Das ständige Streben nach Förderung und Produktivität sind die am meisten geschätzten Eigenschaften.',
  '_MISIJA_PARAGRAF_7': 'Das Image eines erwünschten Arbeitgebers bilden wir durch ständige Verbesserung von Arbeitsbedingungen und der gesamten Mitarbeiterzufriedenheit, sowie korrekte Bewertung der effizienten Arbeit.',
  '_MISIJA_PARAGRAF_8': ' Als sozial verantwortliches Unternehmen unterstützen wird den Umweltschutz, die Zusammenarbeit mit Bildungs- und Kultureinrichtungen, um dadurch die Bildungsqualität und das Wertesystem in der Umgebung zu verbessern.',
  '_MISIJA_PARAGRAF_9': 'Vision',
  '_MISIJA_PARAGRAF_10': 'Alfa Therm soll ein organisiertes Unternehmen werden, gestützt auf Zuständigkeiten und Verantwortung der Mitarbeiter, das eigene Zukunft auf dem gegenseitigen Vertrauen und Respekt sowohl innerhalb als auch außerhalb der Firma baut. Moderne und energieeffiziente Lösungen sind im Interesse von Investoren. Zum Vorreiter in den Serviceleistungen dieser Region mit modernem Call-Zentrum und neuen Leistungen aufsteigen bzw. als solcher bestehen.',
  '_MISIJA_PARAGRAF_11': 'Politik',
  '_MISIJA_PARAGRAF_12': 'Wir stärken das Markenzeichen Alfa Therm durch Sicherheitsgefühl und Kundenvertrauen.',
  '_MISIJA_PARAGRAF_13': 'Wir bieten optimale, energieefiiziente Lösungen mit dem Ziel der Investorenzufriedenheit an.',
  '_MISIJA_PARAGRAF_14': 'Wir sichern die Serviceleistungen für unsere Kunden und strategische Partner.',
  '_MISIJA_PARAGRAF_15': 'Wir gehen keine Kompromisse bezüglich der Qualität unter dem Alfa Therm - Niveau ein.',
  '_MISIJA_PARAGRAF_16': 'Wir sichern Verträge, die das Unternehmensinteresse und die Einbringlichkeit schützen, ohne die Investoren zu beschädigen.',
  '_MISIJA_PARAGRAF_17': 'Durch korrekte Beziehungen zu Lieferanten und Kooperanten sichern wir nachhaltige Bedingungen und bessere Marktposition.',
  '_MISIJA_PARAGRAF_18': 'Durch das Respektieren von Menschenrechten, Dienstvorschriften und ähnliches, sichern wir gute Verhältnisse innerhalb und außerhalb des Unternehmens.',
  '_MISIJA_PARAGRAF_19': 'Wir sind verantwortungsvolle Gesellschaftsmitglieder, mit Gehör für die gesellschaftlichen und sozialen Probleme.',
  '_MISIJA_PARAGRAF_20': 'Die wichtigste durch uns verkaufte Ausrüstung wird von unseren Servicetechnikern in Betrieb genommen.',
  '_MISIJA_CITAT_1': 'WIR HABEN IMMER DIE ',
  '_MISIJA_CITAT_2': 'LÖSUNG',

  //STIPENDIJE
  '_STIPENDIJE_PARAGRAF_1': 'Wir in Alfa Therm sind der festen Überzeugung, dass die Jugend diejenige ist, die das LICHT der Zukunft trägt. Dies beweisen wir durch die Beschäftigung und Ausbildung eines jungen Mitarbeiterstabs.',
  '_STIPENDIJE_PARAGRAF_2': 'Während der letzten Jahre hat Alfa Therm einer Reihe von Studenten in technischen Fächern Stipendien ermöglicht, von denen einige schon Teil unserer Belegschaft sind.',
  '_STIPENDIJE_PARAGRAF_3': 'Studenten in technischen Fächern: Maschinenbau, Elektrotechnik, Informatik / Computertechnik, Mechatronik usw. können sich ständig bewerben.',
  '_STIPENDIJE_PARAGRAF_4': 'Ihre Bewerbung (Kopie des Abschlusszeugnisses der Sekundarschule, Index und ein Bewerbungsschreiben) können sie an alfatherm@alfatherm.com schicken, oder diese persönlich vorbeibringen.',
  '_STIPENDIJE_PARAGRAF_5': 'Melden sie sich.',

  //NATJECAJI
  '_NATJECAJI_PARAGRAF_1': 'Leider haben wir zurzeit keine offenen Stellen offen. Wir freuen uns über Ihr Interesse, bitte senden Sie Ihren Lebenslauf an alfatherm@alfatherm.com',
  '_NATJECAJI_PARAGRAF_2': ' ',
  '_PRIJAVITE_SE': 'BEWERBEN SIE SICH',
  '_IME': 'Vorname',
  '_EMAIL': 'E-mail',
  '_TELEFON': 'Telefon',
  '_PORUKA': 'Nachricht',
  '_ZIVOTOPIS': 'Lebenslauf',
  '_SIGURNOSNI_KOD': 'Sicherheitscode',
  '_POSALJI': 'Senden',
  '_PREZIME': 'Nachname',
  '_TVRTKA': 'Firma',
  '_ADRESA_TVRTKE': 'Standort',
  '_KOMENTAR': 'Kommentar',

  //PROFIL TVRTKE
  '_PROFIL_TVRTKE_NASLOV_1': 'ALFA THERMS GESCHICHTE',
  '_PROFIL_TVRTKE_NASLOV_2': 'SOZIALE VERANTWORTUNG',
  '_PROFIL_TVRTKE_NASLOV_3': 'DIE JUGEND',
  '_PROFIL_TVRTKE_NASLOV_4': 'ENERGIE',
  '_PROFIL_TVRTKE_PARAGRAF_1': 'Die Firma Alfa Therm d.o.o. bietet ihren Kunden eine Reihe von Dienstleistungen aus dem Bereich der Beheizung, Klimatisierung, Belüftung, der technischen Pläne, präzisen Klimatisierung, Automatisierung, zentralen Überwachungssysteme sowie andere dazugehörige Dienstleistungen.',
  '_PROFIL_TVRTKE_PARAGRAF_2': 'Unsere Dienstleistungen sind gestaltet, um die Anforderungen der Klienten zu erfüllen, wobei in erster Linie auf Qualität gesetzt wird.',
  '_PROFIL_TVRTKE_PARAGRAF_3': 'Die Firma wurde 1987 gegründet. Anfangs war dies ein Familienunternehmen mit einigen Angestellten. Im Jahre 1996 beginnt die Expansion, seitdem das Einkommen sowie die Zahl der Angestellten ständig wächst. Einer der Hauptgründe für die Expansion sind die Angestellten, die Experten aus verschiedenen Gebieten sind, sowie eine ständige Weiterbildung und Spezialisierung, wodurch wir in der Lage sind, die kompliziertesten Anforderungen zu erfüllen.',
  '_PROFIL_TVRTKE_PARAGRAF_4': 'Neben ständiger technischer Ausbildung, die die Basis unserer Geschäftstätigkeit bildet, investiert die Firma auch in die Organisation, wobei computergestützte Prozess- und Projektleitung und –verwaltung verwendet wird.',
  '_PROFIL_TVRTKE_PARAGRAF_5': 'Alfa Therm besteht aus zwei Schwesterunternehmen:',
  '_PROFIL_TVRTKE_PARAGRAF_6': 'Alfa Therm d.o.o. Mostar (Bosnien und Herzegowina)',
  '_PROFIL_TVRTKE_PARAGRAF_7': 'Alfa Therm d.o.o. Metković (KROATIEN)',
  '_PROFIL_TVRTKE_PARAGRAF_8': 'Durch diese ist das Gebiet von ganz Bosnien und Herzegowina, sowie der südliche Teil von Kroatien abgedeckt. Wir befinden uns auch in Montenegro und auf dem Kosovo. Alfa Therm ist eine stabile und anerkannte regionale Firma, die ein Symbol für Wissen, Zuverlässigkeit und Qualität ist.',
  '_PROFIL_TVRTKE_PARAGRAF_9': 'Verantwortung gegenüber unserer Gemeinde ist Teil unserer Mission.',
  '_PROFIL_TVRTKE_PARAGRAF_10': 'Unser Slogan "Energie und die Jugend treiben die Welt voran – schützen wir sie!" zeugt am meisten davon, in welchen Segmenten wir am aktivsten sind, wenn es um soziale Verantwortung geht.',
  '_PROFIL_TVRTKE_PARAGRAF_11': 'Neben Stipendien und Hilfen für Studenten, unterstützen wir ständig verschiedene Kultur- und Sportveranstaltungen, Schulen und Fakultäten. Beschäftigung und Ausbildung von Jugendlichen sind einer der wirksamsten Wege, in das Wohl und die Zukunft der gesamten Gesellschaft zu investieren.',
  '_PROFIL_TVRTKE_PARAGRAF_12': 'Ein Projekt zur Jugendausbildung auf das wir besonders stolz sind, ist das',
  '_PROFIL_TVRTKE_PARAGRAF_13': ', wo jungen Menschen von 6 bis 26 Jahren die Möglichkeit geboten wird, praktisches Wissen aus der Elektronik, Robotik und Informatik zu erwerben.',
  '_PROFIL_TVRTKE_PARAGRAF_14': 'Neben der Jugend, fokussieren wir uns auch auf die Energie und deren Nutzbarkeit.',
  '_PROFIL_TVRTKE_PARAGRAF_15': 'Da wir uns über die Exploitation von Ressourcen und den erhöhten Verbrauch, dessen Erhöhung ungerechtfertigt ist, bewusst sind, bieten wir unseren Kunden energieeffiziente Lösungen an.',
  '_PROFIL_TVRTKE_CITAT_1': 'MIT MEHR ALS 30 JAHREN ERFOLGREICHER ARBEIT SIND WIR EIN STABILES UND RESPEKTABLES UNTERNEHMEN GEWORDEN, DAS EIN SYMBOL DES WISSENS, ZUVERLÄSSIGKEIT UND QUALITÄT IST.',
  '_PROFIL_TVRTKE_CITAT_2.1': 'Energie ',
  '_PROFIL_TVRTKE_CITAT_2.2': 'schützen, in die ',
  '_PROFIL_TVRTKE_CITAT_2.3': 'Jugend  ',
  '_PROFIL_TVRTKE_CITAT_2.4': 'investieren.',
  '_CENTAR_TEHNICKU_KULTURU': '  Zentrum für technische Bildung in Mostar',

  //KOMPLETNOST USLUGE
  '_KOMPLETNOST_USLUGE_PARAGRAF_1': 'Mit über 30 Jahren Berufserfahrung, haben wir die Möglichkeit unseren Kunden durch unser Wissen und unsere Ressourcen ein Service-Komplettpaket anzubieten, nach dem Model „SCHLÜSSELFERTIG“.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_2': 'Durch unsere Erfahrung und unsere technischen Lösungen sind wir bereit, alle Anforderungen zu erfüllen, wie schwierig diese auch sein mögen, wobei die Optimalität der Lösung immer beachtet wird.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_3': 'Wir haben einen individuellen Zugang zu jedem Kunden, mit besonderer Hingabe und Aufmerksamkeit für deren Wünsche und Bedürfnisse.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_4': 'Wir haben eine lange Liste an Referenzen, mit vielen fertig gestellten Gebäuden für verschiedene Zwecke.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_5': 'Für alle angegebenen Arten von Gebäuden bieten wir ein ganzes Leistungsspektrum und lebenslange Instandhaltung an, womit eine allumfassende Lösung gesichert wird – von der Idee, dem Entwurf, der Ausführung und am Ende der Wartung und der Instandhaltung.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_6': 'Dank der Vollständigkeit und der Qualität unserer Dienstleistungen, haben uns unsere Kunden die bedeutendsten Gebäude in der Region anvertraut.',
  '_KOMPLETNOST_USLUGE_PARAGRAF_7': 'EINIGE DER VIELEN DIENSTLEISTUNGEN, DIE WIR ANBIETEN, SIND:',
  '_KOMPLETNOST_USLUGE_LIST_1.1': 'Bürogebäude',
  '_KOMPLETNOST_USLUGE_LIST_1.2': 'Produktionsanlagen und technische Anlagen',
  '_KOMPLETNOST_USLUGE_LIST_1.3': 'Verkaufszentren',
  '_KOMPLETNOST_USLUGE_LIST_1.4': 'Wohnhäuser',
  '_KOMPLETNOST_USLUGE_LIST_1.5': 'Hotels und Restaurants',
  '_KOMPLETNOST_USLUGE_LIST_1.6': 'Sakrale Gebäude',
  '_KOMPLETNOST_USLUGE_LIST_1.7': 'Krankenhäuser',
  '_KOMPLETNOST_USLUGE_LIST_1.8': 'Bildungseinrichtungen und öffentliche Einrichtunge',
  '_KOMPLETNOST_USLUGE_LIST_1.9': 'Räumlichkeiten für besondere Zwecke',
  '_KOMPLETNOST_USLUGE_LIST_1.10': 'Hochzeitssalons',
  '_KOMPLETNOST_USLUGE_LIST_1.11': 'Sporthallen',
  '_KOMPLETNOST_USLUGE_LIST_1.12': 'Wasserkraftwerke (Klimatisierung mit zentralem Kontrollsystem)',
  '_KOMPLETNOST_USLUGE_LIST_2.1': 'Vorbereitung der Projektdokumentation für Heizung und Klimaanlage',
  '_KOMPLETNOST_USLUGE_LIST_2.2': 'Projektentwicklung der automatischen Steuerung',
  '_KOMPLETNOST_USLUGE_LIST_2.3': 'Installation von Klimaanlagen und die Inbetriebnahme',
  '_KOMPLETNOST_USLUGE_LIST_2.4': 'Die Produktion von Schlosser-Blechkomponenten',
  '_KOMPLETNOST_USLUGE_LIST_2.5': 'Die Wartung der installierten Geräte',
  '_KOMPLETNOST_USLUGE_LIST_2.6': 'Vorbeugende Wartung',
  '_KOMPLETNOST_USLUGE_LIST_2.7': 'Energieeffizienz-Projekte',

  //ENERGETSKA EFIKASNOST
  '_ENERGETSKA_EFIKASNOST_PARAGRAF_1': 'Unsere Firma legt viel Wert auf Energieeffizienz, sowie auf eine reine und gesunde Umwelt. Systeme die jahrelang in Betrieb sind, können zusätzliche Ressourcen verbrauchen. Dies führt über die Jahre hinweg zu hohen Kosten. Alfa Therm bietet als Teil seiner Leistungen auch die Weiterentwicklung von Energiesystemen. Dies erleichtert Kunden die Arbeit und verringert die Kosten im täglichen Betrieb.',
  '_ENERGETSKA_EFIKASNOST_PARAGRAF_2': 'Neben allumfassenden Lösungen für neue Gebäude, bieten wir die Weiterentwicklung und Verbesserung von bestehenden Systemen. Durch systematische Analysen kommen wir zu Daten, die präzise Angebote für unsere Kunden ermöglichen, wobei große Einsparungen in der Verwertung möglich sind. Unsere Analyse enthält Daten über die Höhe der Investition, die Rückzahlungsfrist und die Höhe der Einsparungen.',
  '_ENERGETSKA_EFIKASNOST_CITAT_1.1': 'Energie ist die essenz des',
  '_ENERGETSKA_EFIKASNOST_CITAT_1.2': 'lebensen',

  //PROJEKTIRANJE
  '_PROJEKTIRANJE_NASLOV': 'ZUSAMMEN ZUR OPTIMALEN LÖSUNG.',
  '_PROJEKTIRANJE_PARAGRAF_1': 'Zu dem Alfa Therm Projektteam gehören über zehn Diplom-Ingenieure, sowie autorisierte Projektanten, die in Besitz aller notwendigen Lizenzen für Projektierung und Aufsicht sind.',
  '_PROJEKTIRANJE_PARAGRAF_2': 'Unser Ingenieuren-Team verfolgt ständig die Entwicklung neuer Technologien und arbeitet eng mit verschiedenen wissenschaftlichen Einrichtungen zusammen, um die bestehenden Lösungen weiterzuentwickeln.',
  '_PROJEKTIRANJE_PARAGRAF_3': 'Alle Verfahren werden von erfahrenen Leitern überwacht und geleitet, welche die Verantwortung für den Abschluss des Projektes nach den vereinbarten Anforderungen und Qualitätsstandards übernehmen.',
  '_PROJEKTIRANJE_PARAGRAF_4': 'Lösungen werden den Kunden, ihren Wünschen und Bedürfnissen nach, angepasst, wobei immer auf Verlässlichkeit und ein optimales Verhältnis von Investition und Verwertung Wert gelegt wird.',
  '_PROJEKTIRANJE_PARAGRAF_5': 'Alle Programme und Werkzeuge, die wir bei der Projektierung verwenden, sind lizenziert.',
  '_PROJEKTIRANJE_PARAGRAF_6': 'Unsere Ingenieure folgen die neuesten technologischen Fortschritte und Trends durch ständige Spezialisierung nach den neuesten weltweiten Standards, wie durch Fachpublikationen, Kongresse, Seminare sowie durch Trainings renommierter Hersteller von Ausrüstung (Siemens, York, Emerson, Daikin, Danfos...)',
  '_PROJEKTIRANJE_PARAGRAF_7': 'DIE FIRMA ALFA THERM D.O.O. BIETET IHREN KUNDEN EINE REIHE VON DIENSTLEISTUNGEN AUS DEM BEREICH DER BEHEIZUNG, KLIMATISIERUNG, BELÜFTUNG, DER TECHNISCHEN PLÄNE, PRÄZISEN KLIMATISIERUNG, AUTOMATISIERUNG, ZENTRALEN ÜBERWACHUNGSSYSTEME SOWIE ANDERE DAZUGEHÖRIGE DIENSTLEISTUNGEN.',
  '_KONTAKTIRAJTE_NAS': 'Kontaktieren Sie uns',

  //BIM PROJEKTIRANJE INSTALACIJA
  '_BIM': 'BIM ',
  '_BIM_PROJEKTIRANJE_PARAGRAF_1': '(Building Information Modeling) ist ein intelligentes auf Modellen basiertes Projektierungsverfahren und bietet Daten an, die zur besseren Planung, Projektierung, Gebäudebau und -verwaltung benutzt werden. Das sind komplette 3D bzw. 5D Modelle, die zur Zusammenarbeit anregen und die Aufgaben haben, die Baukosten zu reduzieren sowie die Zeit und das Geld zu sparen.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_2': 'Da Alfa Therm ein Unternehmen ist, das ständig nach neuen Methoden der Geschäftsförderung sucht und neue Dienstleistungen entwickelt, haben wir die Bedeutung von BIM – Installationsprojektierung für den Bausektor erkannt. Auf diese Weise möchten wir effizienter vorgehen und die Kundenbedürfnisse erkennen. Der Weltmarkt erfordert hochqualitative Lösungen und deswegen wurde der Beschluss gefasst, in die Entwicklung von BIM – Installationsprojektierung zu investieren.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_3': 'Unsere geschulten HVAC-Experten mit jahrelanger Erfahrung in verschiedenen Projekten bringen die Projektierungsfähigkeit durch die Anwendung des BIM - Modellierens auf ein höheres Niveau, was uns die Ausweitung der Geschäftstätigkeit und die Durchführung von komplexen Projekten ermöglicht.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_4': 'Planen Sie im Voraus durch die Anwendung der BIM – Installationsprojektierung, senken Sie Ihre Kosten und sichern Sie es ab, dass Ihr HVAC-System optimal und wirtschaftlich funktioniert!',
  '_BIM_PROJEKTIRANJE_PARAGRAF_5': 'Die Kundenzufriedenheit ist die beste Bestätigung unserer erfolgreichen Geschäftstätigkeit, was zur Steigerung des professionellen Ansehens von Alfa Therm beiträgt. Wenn Sie einen Bau planen, der die Anwendung von einem HVAC-System im MIB-Modell erfordert, dann nehmen Sie Kontakt mit uns auf. Wir werden Ihnen gerne beim erfolgreichen Bau helfen.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_6': 'Alfa Therm arbeitet in den letzten Jahren mit dem größten Projektierungshaus in Europa, dem schwedischen',
  '_BIM_PROJEKTIRANJE_PARAGRAF_6.1': ' zusammen.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_7': 'SWECO ist ein Unternehmen mit über 14.500 Beschäftigten, einer 125 Jahre langen Tradition und ist der Spitzenreiter auf dem Gebiet von komplexen Projektierungs- und Ingenieurleistungen.Als offizieller Partner von SWECO arbeiten wir an den komplexesten Installationsprojekten für verschiedene Infrastrukturobjekte zusammen.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_8': 'Diese Zusammenarbeit mit Alfa Therm eröffnete eine neue Seite in der Geschäftstätigkeit, es wurde eine neue BIM - Abteilung für die Installationsprojektierung gegründet und die Entwicklung und Beschäftigung von jungen Experten ermöglicht.',
  '_BIM_PROJEKTIRANJE_CITAT_1': 'BIM-INSTALLATIONSPROJEKTIERUNG',
  '_BIM_PROJEKTIRANJE_CITAT_2': 'PLANEN FÜR DIE ZUKUNFT!',

  //AUTOMATSKA REGULACIJA
  '_AUTOMATSKA_REGULACIJA_NASLOV_1': 'ZENTRALISIERTE ÜBERWACHUNGS- UND KONTROLLSYSTEME',
  '_AUTOMATSKA_REGULACIJA_NASLOV_2': 'BASISFUNKTIONEN DES ZENTRALEN ÜBERWACHUNGSSYSTEMS',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_1': 'Unsere Kraft liegt in unserer Fähigkeit, die kompliziertesten Systeme der automatischen Regulierung (Siemens PX, Siemens SYNCO, Johnson Controls FX, Johnson Controls FAC) zu projektieren, programmieren, installieren, in Betrieb zu nehmen und zu warten.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_2': 'Wir haben mehr als 10 Jahre Erfahrung mit Systemen der automatischen Regulierung an verschiedenen Arten von Gebäuden - von intelligenten Häusern bis zu komplexen Systemen zentraler Überwachungssteuerung bei großen Gebäuden.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_3': 'Automatische Regulierung sichert einen energieeffiziente Betrieb des Systems.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_4': 'Wir bieten unseren Kunden folgende Lösungen:',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_5': 'Innovative Lösungen in Automatisierungssystemen sichern Kunden eine deutliche Energiekostensenkung, sowie Senkung anderer Kosten bei der Nutzung des Systems mit erhöhtem Komfort.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_6': 'Zur vereinfachten Kontrolle und Überwachung der verwendeten Ausrüstung, sowie zur Senkung des Verbrauchs, werden heutzutage immer mehr zentrale Überwachungssysteme verwendet.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_7': 'Ein zentralisiertes Überwachungs- und Kontrollsystem ist ein Computer-basiertes Kontrollsystem, das in ein Gebäude eingebaut wird, das den Zustand der elektrischen und mechanischen Ausrüstung überwacht und reguliert, wie z.B. die Ausrüstung für die Klimatisierung, Beheizung, Kühlung, Beleuchtung, für den Feuerschutz, für Sicherheitssysteme usw.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_8': 'Was die Energie angeht, so wird der mit Abstand größte Teil (bis zu 80%) der Gesamtenergie in Gebäuden für die Klimatisierung (Kühlung, Heizung, Entfeuchtung, Lüftung) und die Warmwasserbereitung verwendet.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_9': 'Demnach lohnt es sich in Kontrollsystemen zu investieren, die den Energieverbrauch optimieren, die Kontrolle und Regulierung vereinfachen, und den Komfort in den Räumlichkeiten erhöhen können.',
  '_AUTOMATSKA_REGULACIJA_LIST_1.1': 'Kontrollsystem für Klimatisierung, Beheizung und Kühlung',
  '_AUTOMATSKA_REGULACIJA_LIST_1.2': 'Energie-Kontrollsystem',
  '_AUTOMATSKA_REGULACIJA_LIST_1.3': 'Beleuchtungssteuerung',
  '_AUTOMATSKA_REGULACIJA_LIST_1.4': 'Zentralisierte Überwachungs- und Kontrollsysteme',
  '_AUTOMATSKA_REGULACIJA_LIST_2.1': 'Systemmanagement',
  '_AUTOMATSKA_REGULACIJA_LIST_2.2': 'Überwachung des Betriebs des Systems',
  '_AUTOMATSKA_REGULACIJA_LIST_2.3': 'Optimierung der Energiekosten',

  //MONTAZA
  '_MONTAZA_NASLOV_1': 'INTERNE TECHNISCHER EMPFANG ZUR QUALITÄTSSICHERUNG',
  '_MONTAZA_NASLOV_2': 'WERKSTATT UND LAGER',
  '_MONTAZA_PARAGRAF_1': 'Eine ordnungsgemäße und zuverlässige Montage garantiert einen ordnungsgemäßen Betrieb und die Effizienz des Systems. Deshalb bestehen Alfa Therms Montageteams nur aus spezialisierten und geschulten Mitarbeitern.',
  '_MONTAZA_PARAGRAF_2': 'Vorgeschriebene Montageverfahren sind eine zusätzliche Garantie für erfolgreich abgeschlossene Projekte.',
  '_MONTAZA_PARAGRAF_3': 'Eine zusätzliche Unterstützung für die Installateure ist die Werkstatt, in der wir selbständig Elemente, die für die Integration des ganzen Systems erforderlich sind, herstellen und vorbereiten.',
  '_MONTAZA_PARAGRAF_4': 'Dank unserer Lagerkapazitäten sind alle Elemente immer verfügbar. Dies macht es für unsere Kunden einfacher und gibt ihnen eine zusätzliche Sicherheit.',
  '_MONTAZA_PARAGRAF_5': 'Um alle technischen Eigenschaften des Systems und die Genauigkeit der Montagearbeiten zu überprüfen, hat Alfa Therm einen internen technischen Empfang, um selber mögliche Abweichungen bei der Montage zu erkennen und zu entfernen.',
  '_MONTAZA_PARAGRAF_6': 'Um die Montagezeit bei Gebäuden zu verkürzen, erstellen und bereiten wir verschiedene Elemente (Kanäle, Trenner, Träger...) selbständig vor.',
  '_MONTAZA_PARAGRAF_7': 'Die reiche Erfahrung unserer Montagearbeiter, sowie eine große Anzahl komplexer Gebäude sind eine Garantie für die Qualität unserer Installationen.',

  //SERVIS
  '_SERVIS_PARAGRAF_1': 'Ein 24 Stunden, sieben Tage in der Woche verfügbarer Kundenservice, bestätigt unseren Einsatz bei der Kundenbetreuung.',
  '_SERVIS_PARAGRAF_2': 'Unser Kundenservice ist qualifiziert, den Defekt schnell zu erkennen und in Absprache mit dem Kunden den Defekt optimal und schnell zu sanieren, dank dem internen Ersatzteillager.',
  '_SERVIS_PARAGRAF_3': 'Alfa Therms Serviceteams sind spezialisierte Handwerker mit einem Servicefahrzeug, das mit professionellem Werkzeug ausgestattet ist.',
  '_SERVIS_PARAGRAF_4': 'Eine regelmäßige Schulung der Handwerker von Seiten der Ausrüstungshersteller aber auch durch unsere interne Schulung, versichert schnelle und qualitativ hochwertige Service-Einsätze, sowie die Funktionsfähigkeit der in Betrieb gesetzten Ausrüstung.',
  '_SERVIS_PARAGRAF_5': 'Vorgeschriebene Verfahren zur Ausführung von Service-Einsätzen und Verfahren zur Arbeitssicherheit sowie zum Brandschutz sind ein Muss bei den täglichen Wartungsarbeiten.',

  //ODRZAVANJE
  '_ODRZAVANJE_PARAGRAF_1': 'Vorbeugende Wartung zeigte sich als eine der besten Dienstleistungen, und die beste Wahl unserer Kunden, da dadurch Folgendes erzielt wird:',
  '_ODRZAVANJE_PARAGRAF_2': 'Durch technische und hygienische vorbeugende Wartung wollen wir eine neue Lebensqualität in die Räumlichkeiten unserer Kunden einbringen, und dadurch die Eingriffe in der Heiz- und Kühlungsperiode verringern.',
  '_ODRZAVANJE_PARAGRAF_3': 'Unsere Service-Abteilung erstellt einen Plan für die vorbeugenden Wartung, sodass die Ausrüstung nach einem entwickelten Plan gewartet wird. Dienstbereite Service-Teams können nach Bedarf 24 Stunden am Tag zur Verfügung stehen, wenn dies vertraglich vereinbart ist. Die Service-Abteilung kümmert sich um die Spezialisierung der Mitarbeiter und um die immer bestehenden Vorräte an Ersatzteilen.',
  '_ODRZAVANJE_PARAGRAF_4': 'Sie können sich 24 Stunden am Tag auf uns verlassen.',
  '_ODRZAVANJE_LIST_1.1': 'reduzierter Energieverbrauch',
  '_ODRZAVANJE_LIST_1.2': 'Verlängerung der Lebensdauer',
  '_ODRZAVANJE_LIST_1.3': 'Sicherheit der Funktionalität während der Saison',
  '_ODRZAVANJE_LIST_1.4': 'Hygiene, mit verringertem Risiko der Übertragung von Bakterien und Viren',
  '_ODRZAVANJE_LIST_1.5': 'sauberere und gesündere Luft',

  //ENERGETSKI AUDIT
  '_ALFA_THERM': 'Alfa Therm d.o.o. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.1': 'hat von Seite des Föderalen Ministeriums für Raumplanung am 03.04.2015. die ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.2': 'Autorisation Nummer UPI/03-23-2-341/14 SK ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.3': 'für Energiekontrollen und energetische Zertifizierung von Wohngebüden und Nichtwohngebäuden mit komplexen technischen Systemen bekommen.',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.1': 'Der Energieaudit ',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.2': 'ist ein Verfahren mit dem Ziel den bestehenden Energieverbrauch und die energetischen Eigenschaften von Gebäuden zu analysieren und definiert die Rentabilität der Investierung in Energieeinsparung. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.1': 'Das Energiezertifikat ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.2': 'ist ein Dokument, das die energetischen Eigenschaften des Gebäudes darstellt und die Übersicht in den Energieverbrauch und die Qualitet des Gebäudes bezüglich Energieeffizienz ermöglicht.Das nach dem Energieaudit erhaltene Zertifikat umfasst auch den Antrag für rentable Verbesserung energetischer Eigenschaften, um den Energieverbrauch zu ermäßigen. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_4': 'Durch Energieeinsparung und Verbrauch erneubarer Energiquellen sparen wir selbst Mittel und die energetische Klasse des Gebäudes kann den Marktwert des Gebäudes beinflussen. Bezüglich Energieverbrauch kann ein Objekt in 8 Klassen gestellt werden: A+, A, B, C, D, E, F, G.',
  '_ENERGETSKI_AUDIT_PARAGRAF_5': 'Die Energieeffizienzverbesserung des Gebäudes kann die Lebensqualität euch und euren Hausbewohnern verbessern, die Lebensdauer eures Gebäudes verlängern und den Energieaufwand wie auch die Instandhaltungskosten ermäßigen.',

   //REFERENCE
   '_JAVNE_USTANOVE':'Öffentliche Einrichtungen',
   '_OBJEKTI_POSEBNE_NAMJENE':'Einrichtungen für Besondere Zwecke',
   '_OSTALI_OBJEKTI':'Weitere Einrichtungen',
   '_POSLOVNE_ZGRADE':'Geschäftsgebäude',
   '_PRODAJNI_CENTRI':'Verkaufzentren',
   '_SAKRALNI_OBJEKTI':'Sakrale Objekte',
  '_UGOSTITELJSKI_OBJEKTI': 'Gaststätteneinrichtungen',

  '_MISLJENJE': 'Ihre Meinung ist wichtig!',
  '_PRIMJEDBE_TEKST': 'Wir sind bemüht, unsere Geschäftstätigkeit und die Leistungsqualität zu fördern. Ihre Vorschläge, Fragen, aber auch Einwendungen sind willkommen. Daher bitten wir unsere geschätzten Kunden, Lieferanten, Partner und Mitarbeiter um Kommentare. Wir möchten, dass Sie zufrieden sind und die erwartete Leistung bekommen. Gemeinsam schaffen wir mehr!',
  '_PRIMJEDBE_TEKST_2': 'Wenn Sie anonym bleiben möchten, überspringen Sie die Kontaktfelder.',
};
