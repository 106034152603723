import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TRANSLATION_PROVIDERS, TranslateService, TranslatePipe } from './service/translate/index';

import { SiteService } from './service/site.service';
import { AdminService } from './service/admin.service';

import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { appRoutes } from './routes';

import { CookieComponent } from './components/cookie/cookie.component';

@NgModule({
  declarations: [
    AppComponent,
    CookieComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes),
    CommonModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
  ],
  exports: [RouterModule],
  providers: [TRANSLATION_PROVIDERS, TranslateService, SiteService, AdminService, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
