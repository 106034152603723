export const LANG_HR_NAME = 'hr';

export const LANG_HR_TRANS = {
  //INFO
  '_ERROR': 'Dogodila se greška. Pokušajte ponovno!',
  '_ERROR_IMAGE': 'Molimo unesite fotografiju!',
  '_USPJESNO_OBRISANO': 'Uspješno ste obrisali podatak!',
  '_OBAVEZNA_POLJA': 'Potrebno je da unesete sve potrebne podatke za određeni jezik!',
  '_USPJESNO_DODANA_NOVOST': 'Uspješno ste dodali novost!',
  '_USPJESNO_IZMJENJENA_NOVOST': 'Uspješno ste izmijenili novost!',
  '_POLJA_OBAVEZNA': 'Sva polja su obavezna!',
  '_USPJESNO_DODAN_TIP_REFERENCE': 'Uspješno ste dodali tip reference!',
  '_NIJE_MOGUCE_OBRISATI': 'Podatak se koristi, te ga nije moguće obrisati!',
  '_USPJESNO_IZMJENJEN_TIP_REFERENCE': 'Uspješno ste izmijenili tip reference!',
  '_USPJESNO_DODATA_REFERENCA': 'Uspješno ste dodali referencu!',
  '_USPJESNO_IZMJENJENA_REFERENCA': 'Uspješno ste izmijenili referencu!',
  '_USPJESNO_DODAN_PARTNER': 'Uspješno ste dodali partnera!',
  '_USPJESNO_IZMJENJEN_PARTNER': 'Uspješno ste izmijenili partnera!',
  '_USPJESNO_IZMJENJEN_SADRZAJ': 'Uspješno ste izmijenili sadržaj!',
  '_USPJESNO_DODAN_SADRZAJ': 'Uspješno ste dodali novi sadržaj!',
  '_NETACNI_PODACI': 'Niste unijeli tačne korisničke podatke!',
  '_DODAJ_PITANJA': 'Dodaj pitanja',
  '_UNESI_TEKST_PITANJA': 'Unesi pitanje',
  '_EMAIL_ANKETA': 'Email anketa',
  '_EMAIL_PRIMJEDBE': 'Email primjedbe',
  '_EMAIL_NATJECAJI': 'Email natječaji',
  '_EMAIL_KONTAKT': 'Email kontakt',
  '_EMAIL_SERVIS': 'Email servis',
  '_LOZINKA_VALIDACIJA': 'Potrebno je da lozinka ima najmanje 8 karaktera!',
  '_KORISNICKO_IME': 'Korisničko ime',
  '_UNESI_KORISNICKO_IME': 'Unesi korisničko ime',
  '_LOZINKA': 'Lozinka',
  '_UNESI_LOZINKU': 'Unesi lozinku',
  '_USPJESNA_PRIJAVA': 'Uspješno ste se prijavili na natječaj!',
  '_JEZIK': 'Jezik',
  '_SLAZEM_SE_SA_PRAVILIMA':'Slažem se sa pravilima privatnosti',
  '_DATUM': 'Datum',

  //RUTE
  'hr/Pocetna': 'hr/Pocetna',
  'en/Home': 'hr/Pocetna',
  'de/Startseite': 'hr/Pocetna',

  'hr/Reference': 'hr/Reference',
  'en/References': 'hr/Reference',
  'de/Referenzen': 'hr/Reference',

  'hr/Partneri': 'hr/Partneri',
  'en/Partners': 'hr/Partneri',
  'de/Partners': 'hr/Partneri',

  'hr/Novosti': 'hr/Novosti',
  'en/News': 'hr/Novosti',
  'de/Nachrichten': 'hr/Novosti',

  'hr/Misija_i_vizija': 'hr/Misija_i_vizija',
  'en/Mission_and_vision': 'hr/Misija_i_vizija',
  'de/Mission_und_vision': 'hr/Misija_i_vizija',

  'hr/Natjecaji': 'hr/Natjecaji',
  'en/Open_positions': 'hr/Natjecaji',
  'de/Wettbewerbe': 'hr/Natjecaji',

  'hr/Stipendije': 'hr/Stipendije',
  'en/Scholarships': 'hr/Stipendije',
  'de/Stipendien': 'hr/Stipendije',

  'hr/Profil_tvrtke': 'hr/Profil_tvrtke',
  'en/Company_profile': 'hr/Profil_tvrtke',
  'de/Firmenprofile': 'hr/Profil_tvrtke',

  'hr/Kompletnost_usluge': 'hr/Kompletnost_usluge',
  'en/Completness_of_services': 'hr/Kompletnost_usluge',
  'de/Service-komplettpaket': 'hr/Kompletnost_usluge',

  'hr/Energetska_efikasnost': 'hr/Energetska_efikasnost',
  'en/Energy_efficiency': 'hr/Energetska_efikasnost',
  'de/Energieefizienz': 'hr/Energetska_efikasnost',

  'hr/Projektiranje': 'hr/Projektiranje',
  'en/Hvac_design': 'hr/Projektiranje',
  'de/Projektierung': 'hr/Projektiranje',

  'hr/BIM_projektiranje_instalacija': 'hr/BIM_projektiranje_instalacija',
  'en/BIM_installation_design': 'hr/BIM_projektiranje_instalacija',
  'de/BIM_Installationsprojektierung': 'hr/BIM_projektiranje_instalacija',

  'hr/Montaza': 'hr/Montaza',
  'en/Installation': 'hr/Montaza',
  'de/Montage': 'hr/Montaza',

  'hr/Automatska_regulacija': 'hr/Automatska_regulacija',
  'en/Automated_regulation': 'hr/Automatska_regulacija',
  'de/Automatische_Regulierung': 'hr/Automatska_regulacija',

  'hr/Servis': 'hr/Servis',
  'en/Service': 'hr/Servis',
  'de/Kundenservice': 'hr/Servis',

  'hr/Odrzavanje': 'hr/Odrzavanje',
  'en/Preventive_maintenance': 'hr/Odrzavanje',
  'de/Wartung': 'hr/Odrzavanje',

  'hr/Energetski_audit': 'hr/Energetski_audit',
  'en/Energy_audit': 'hr/Energetski_audit',
  'de/Der_Energieaudit': 'hr/Energetski_audit',

  'hr/Sto_je_ISO': 'hr/Sto_je_ISO',
  'en/What_is_ISO': 'hr/Sto_je_ISO',
  'de/Was_ist_ISO': 'hr/Sto_je_ISO',

  'hr/Primjedbe_i_komentari': 'hr/Primjedbe_i_komentari',
  'en/Comments_and_remarks': 'hr/Primjedbe_i_komentari',
  'de/Kommentare_und_Anmerkungen': 'hr/Primjedbe_i_komentari',

  'hr/Kontakt': 'hr/Kontakt',
  'en/Contact': 'hr/Kontakt',
  'de/Kontakt': 'hr/Kontakt',

  'hr/Servisna_sluzba': 'hr/Servisna_sluzba',
  'en/Call_center': 'hr/Servisna_sluzba',
  'de/Kundendienst': 'hr/Servisna_sluzba',

  'hr/Ispitivanje_zadovoljstva_klijenata': 'hr/Ispitivanje_zadovoljstva_klijenata',
  'en/Customer_Satisfaction_Testing': 'hr/Ispitivanje_zadovoljstva_klijenata',
  'de/Kundenzufriedenheitstest': 'hr/Ispitivanje_zadovoljstva_klijenata',

  //ADMIN
  '_DODAJ_NOVOST': 'Dodaj novost',
  '_NASLOV': 'Naslov',
  '_TEKST': 'Tekst',
  '_AKCIJE': 'Akcije',
  '_UREDI': 'Uredi',
  '_OBRISI': 'Obriši',
  '_HRVATSKI_JEZIK': 'Hrvatski jezik',
  '_ENGLESKI_JEZIK': 'Engleski jezik',
  '_NJEMACKI_JEZIK': 'Njemački jezik',
  '_GALERIJA': 'Galerija:',
  '_GLAVNA_FOTOGRAFIJA': 'Glavna fotografija',
  '_DODAJ_FOTOGRAFIJE': 'Dodaj fotografije',
  '_NAZAD': 'Nazad',
  '_SPREMI': 'Spremi',
  '_NASTAVI': 'Sačuvaj & nastavi',
  '_IZREZI': 'Izrezi',
  '_UNESI_NASLOV': 'Unesi naslov',
  '_DODAJ_REFERENCU': 'Dodaj referencu',
  '_LOKACIJA': 'Lokacija',
  '_OPIS': 'Opis',
  '_TIP_REFERENCE': 'Tip reference',
  '_NAZIV': 'Naziv',
  '_UNESI_NAZIV': 'Unesi naziv',
  '_DODAJ_TIP_REFERENCE': 'Dodaj tip reference',
  '_ODBACI': 'Odbaci',
  '_FOTOGRAFIJA': 'Fotografija',
  '_UNESI_LOKACIJU': 'Unesi lokaciju',
  '_PRIORITET': 'Prioritet',
  '_WEBSITE': 'Web stranica',
  '_UNESI_WEBSITE': 'Unesi web stranicu',
  '_DODAJ_PARTNERA': 'Dodaj partnera',
  '_DODAJ_SADRZAJ': 'Dodaj sadržaj',
  '_UNESI_TEKST': 'Unesi tekst',
  '_ANKETA': 'Anketa',
  '_POSTAVKE': 'Postavke',

  //HEADER
  '_ONAMA': 'O Nama',
  '_ONAMA2': 'ONama',
  '_DJELATNOSTI': 'Djelatnosti',
  '_REFERENCE': 'Reference',
  '_PARTNERI': 'Partneri',
  '_NOVOSTI': 'Novosti',
  '_KONTAKT': 'Kontakt',
  '_SERVISNA_SLUZBA': 'Servisna služba',
  '_SERVISNA_SLUZBA2': 'Servisna_sluzba',
  '_MISIJA_VIZIJA': 'Misija i vizija',
  '_NATJECAJI': 'Natječaji',
  '_STIPENDIJE': 'Stipendije',
  '_PROFIL_TVRTKE': 'Profil tvrtke',
  '_KOMPLETNOST_USLUGE': 'Kompletnost usluge',
  '_ENERGETSKA_EFIKASNOST': 'Energetska efikasnost',
  '_PROJEKTIRANJE': 'Projektiranje',
  '_BIM_PROJEKTIRANJE_INSTALACIJA': 'BIM projektiranje instalacija',
  '_AUTOMATSKA_REGULACIJA': 'Automatska regulacija',
  '_MONTAZA': 'Montaža',
  '_SERVIS': 'Servis',
  '_ODRZAVANJE': 'Održavanje',
  '_ENERGETSKI_AUDIT': 'Energetski audit',
  '_POCETNA': 'Pocetna',
  '_UPRAVLJANJE_KVALITETOM': 'Upravljanje kvalitetom',
  '_STA_JE_ISO': 'Što je ISO?',
  '_ISPITIVANJE_ZADOVOLJSTVA_KLIJENATA': 'Ispitivanje zadovoljstva klijenata',
  '_PRIMJEDBE_KOMENTARI': 'Primjedbe i komentari',
  '_PROCITAJTE_VISE': 'Pročitajte više',

  //FOOTER
  '_BOSNA_HERCEGOVINA': 'BOSNA I HERCEGOVINA',
  '_HRVATSKA': 'HRVATSKA',
  '_DODATNE_INFORMACIJE': 'Dodatne informacije',
  '_CERTIFIKAT': 'CERTIFIKAT ISO 9001',
  '_COPYRIGHT': 'Sva prava pridržana',

  //NATJECAJI
  '_PRIJAVITE_SE': 'Prijavite se',
  '_IME': 'Ime',
  '_EMAIL': 'E-mail',
  '_TELEFON': 'Telefon',
  '_PORUKA': 'Poruka',
  '_ZIVOTOPIS': 'Životopis',
  '_SIGURNOSNI_KOD': 'Sigurnosni kod',
  '_POSALJI': 'Pošalji',
  '_PREZIME': 'Prezime',
  '_TVRTKA': 'Tvrtka',
  '_ADRESA_TVRTKE': 'Adresa tvrtke',
  '_KOMENTAR': 'Komentar',
  '_MOBITEL': 'Mobitel',
  '_OPIS_KVARA': 'Opis kvara',
  '_PRIJAVI_KVAR': 'Prijavi kvar',
  '_USPJESNO_OSTAVLJEN_KOMENTAR': 'Hvala! Uspješno ste ostavili komentar za ALFA THERM d.o.o.',
  '_OZNACITE_CHECKBOX': 'Prije slanja podataka, potrebno je da se složite sa izjavom o privanosti.',
  '_UNESITE_KOMENTAR': 'Prije slanja primjedbe, potrebno je da unesete komentar.',

  '_USPJESNO_POSLANA_ANKETA': 'Uspješno ste ispunili anketu.',
  '_POLJE_KLIJENT_OBAVEZNO': 'Prije slanja ankete, potrebno je da popunite polje klijent.',
  '_POPUNITE_SVA_PITANJA': 'Prije slanja ankete, potrebno je da odgovoriti na sva postavljena pitanja ankete.',
  '_USPJESNO_POSLANA_PORUKA': 'Vaša poruka je primljena.Tim Alfa Therm-a će Vam odgovoriti najkraćem mogućem roku.',

  '_USPJESNO_PRIJAVLJEN_KVAR': 'Vaš kvar je zaprimljen.',

  '_GDPR_NORMA': 'Tvrtka Alfa Therm d.o.o je vlasnik web stranice www.alfatherm.com. Stvorili smo ovu izjavu o privatnosti kako bismo pokazali da brinemo o privatnosti naših korisnika te kako bismo ih informirali o načinima skupljanja i obrade osobnih podataka na ovoj web stranici. Molimo vas da ovu Izjavu pročitate pažljivo kako biste saznali kako skupljamo, obrađujemo, štitimo ili na bilo koji drugi način koristimo vaše osobne podatke.',
  '_GDPR2_NORMA1': 'Vaše osobne podatke prikupljamo samo kada nam svojom voljom date takve informacije prilikom ispunjavanja obrazca ili prilikom slanja servisnog zahtjeva. Neosobne informacije i podaci se mogu automatski prikupljati putem naših Internet servera ili korištenjem „kolačića“. Primjeri informacija o Vašem korištenju stranice uključuju: najposjećenije i najgledanije stranice i poveznice na našoj web stranici, broj ispunjenih obrazaca, vrijeme provedeno na stranici, najpopularnije ključne riječi koji korisnike vode do naše stranice, vaša IP adresa, informacije koje se skupljaju putem kolačića, podaci vašeg uređaja poput postavki hardwarea, sistemske aktivnosti, vrste pretraživača itd. Podatke dobivene o vama koristimo:',
   '_GDPR2_NORMA_STAVKA1': ' Radi uspostave primarnog kanala komunikacije s vama i pružanju usluge;',
  '_GDPR2_NORMA_STAVKA2': 'Radi poboljšanja naše web stranice  i naših usluga(trajno nastojimo poboljšati ponudu naše web stranice na temelju povratnih informacija koje dobivamo od naših posjetitelja); ',
  '_GDPR2_NORMA2': 'Na našoj web stranici ne nudimo niti pružamo usluge ili proizvode trećih strana. Kako bismo zaštitili osobne podatke koje šaljete preko ove web stranice, koristimo fizičke, tehničke i organizacijske mjere sigurnosti.Neprestano nadograđujemo i testiramo našu sigurnosnu tehnologiju.Ograničavamo pristup Vašim osobnim podacima samo onim zaposlenicima koji trebaju znati te informacije kako bi Vam pružili neke koristi ili usluge.Osim toga, educiramo naše zaposlenike o važnosti povjerljivosti podataka te čuvanju privatnosti i zaštiti Vaših podataka.Svi podaci se pohranjuju u bazama podataka i serverima Alfa Therm - a, te pohranjene podatke nećemo prenijeti ili pohraniti u države izvan Europske Unije.' +
    'Vaše osobne podatke čuvamo i štitimo za vrijeme trajanja Vašeg poslovnog odnosa s nama.Osobne podatke koje ste nam dali u poslovne ili kontaktne svrhe redovito brišemo nakon 12 mjeseci od dana kad ste nam dali te podatke.Ako želite znati posjedujemo li i obrađujemo Vaše osobne podatke, ili ako želite pristupiti osobnim podacima koje imamo o vama, molimo Vas da nas kontaktirate na alfatherm@alfatherm.com. ' +
    'Također možete zahtijevati informacije o: svrsi obrade podataka, kategorijama osobnih podataka pod obradom; tko je još izvan naše tvrtke primio Vaše osobne podatke od nas; koji je izvor osobnih podataka(ako ih niste izravno dali nama); i kako dugo ćemo čuvati podatke.Imate pravo na ispravak osobnih podataka koje posjedujemo ako su oni netočni.Također možete, uz određene iznimke, zatražiti da izbrišemo Vaše podatke ili prestanemo s njihovom obradom.Možete tražiti da prestanemo koristiti Vaše osobne podatke u svrhe izravnog marketinga.Korištenjem ove web stranice dajete privolu na ovu politiku privatnosti.',
  '_RECAPTCHA':'Verifikacija nije uspjela!',
  '_PRIHVATI':'Prihvati',
  '_ANKETNI_LIST': 'Anketni list',
  '_POPUNITE_ANKETU': 'Molimo Vas da izdvojite malo vremena i odgovorite na postavljena pitanja u anketi. Vaši odgovori pomažu u nastojanju unaprjeđenja usluga Alfa Therm-a. Unaprijed se zahvaljujemo!',
  '_KLIJENT': 'Klijent',
  '_DODATNO_PITANJE': 'Ako želite, možete navesti Vaše primjedbe ili prijedloge za poboljšanje prema tvrtki ALFA THERM d.o.o',
  '_MISLJENJE': 'Vaše mišljenje je važno!',
  '_PRIMJEDBE_TEKST': 'Nastojimo unapređivati svoje poslovanje i podizati kvalitetu usluga. Vaši prijedlozi, pitanja ali i prigovori su dobrodošli, stoga molimo naše cijenjene kupce, partnere, dobavljače, suradnike kao i djelatnike da ostave svoj komentar.   Želimo da budete zadovoljni i da Vam pružimo uslugu upravo onakvu kakvu očekujete. Zajedno možemo više!',
  '_PRIMJEDBE_TEKST_2': 'Ukoliko želite ostati anonimni, preskočite polja za kontakt.',

  //PROJEKTIRANJE
  '_PROJEKTIRANJE_NASLOV': 'ZAJEDNO DO OPTIMALNOG RJEŠENJA',
  '_PROJEKTIRANJE_IKONA_1': 'STRUČNO I PROFESIONALNO OSOBLJE',
  '_PROJEKTIRANJE_IKONA_2': 'PRAKTIČNO ZNANJE',
  '_PROJEKTIRANJE_IKONA_3': 'SVEOBUHVATNA RJEŠENJA',
  '_PROJEKTIRANJE_IKONA_4': 'FLEKSIBILNOST I BRZINA IZRADE RJEŠENJA',
  '_PROJEKTIRANJE_IKONA_5': 'TIMSKI RAD',
  '_PROJEKTIRANJE_PARAGRAF_7': 'TVRTKA ALFA THERM D.O.O. SVOJIM KLIJENTIMA NUDI NIZ USLUGA IZ OBLASTI GRIJANJA, KLIMATIZACIJE, VENTILACIJE, TEHNIČKIH PLANOVA, PRECIZNE KLIMATIZACIJE, AUTOMATIKE, CENTRALNIH NADZORNIH SUSTAVA I OSTALIH DJELATNOSTI.',
  '_KONTAKTIRAJTE_NAS': 'Kontaktirajte nas',

  //BIM PROJEKTIRANJE INSTALACIJA
  '_BIM': 'BIM ',
  '_BIM_PROJEKTIRANJE_PARAGRAF_1': '(Building Information Modeling) je inteligentni postupak projektiranja koji se temelji na modelima i pruža podatke potrebne za bolje planiranje, projektiranje, izgradnju i upravljanje zgradama. To su kompletni 3D odnosno 5D modeli koji potiču suradnju i imaju zadatak smanjenja poteškoća tijekom izgradnje, te štede vrijeme i novac.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_2': 'Budući da je Alfa Therm poduzeće koje uvijek iznalazi nove načine unaprijeđenja poslovanja i razvija nove usluge, prepoznali smo važnost BIM projektiranja instalacija za građevinski sektor. Na taj način želimo djelovati učinkovitije i prepoznati potrebe klijenata. Svjetsko tržište zahtjeva visokokvalitetna rješenja i zbog toga je donesena odluka investiranja u razvoj BIM projektiranje instalacija.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_3': 'Naši obučeni HVAC stručnjaci sa godinama iskustva na različitim projektima podižu sposobnost projektiranja na višu razinu uz primjenu BIM modeliranja, što nam omogućuje širenje poslova i preuzimanje kopleksnijih projekata.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_4': 'Planirajte unaprijed koristeći BIM projektiranje instalacija te smanjite troškove i osigurajte da vaš HVAC sustav funkcionira na optimalan i ekonomičan način!',
  '_BIM_PROJEKTIRANJE_PARAGRAF_5': 'Zadovoljstvo klijenata nam je najbolja potvrda uspješnog poslovanja što doprinosi podizanju profesionalne reputacije Alfa Therm-a. Ako planirate gradnju koja zahtjeva implementaciju HVAC sustava u BIM modelu, kontaktirajte nas. Rado ćemo vam pomoći pri uspješnoj gradnji.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_6': 'Alfa Therm zadnjih nekoliko godina surađuje sa najvećom projektantskom kućom u Europi, švedskim ',
  '_BIM_PROJEKTIRANJE_PARAGRAF_7': 'SWECO je poduzeće sa preko 14.500 zaposlenih, tradiciju od 125 godina i lider je u oblasti najsloženijih usluga projektiranja i inženjeringa. Kao službeni partneri SWECO-a  surađujemo i na najkopleksnijim projektima instalacija za različite vrste infrastrukturnih objekata.',
  '_BIM_PROJEKTIRANJE_PARAGRAF_8': 'Ova suradnja je za  Alfa Therm otvorila novu stranicu u poslovanju, formiran je novi odjel za BIM projektiranje instalacija,  te je omogućen razvoj i zapošljavanje mladih stručnjaka.',
  '_BIM_PROJEKTIRANJE_CITAT_1': 'BIM PROJEKTIRANJE INSTALACIJA',
  '_BIM_PROJEKTIRANJE_CITAT_2': 'PLANIRANJE ZA BUDUĆNOST!',

  //AUTOMATSKA REGULACIJA
  '_AUTOMATSKA_REGULACIJA_NASLOV_1': 'CENTRALIZIRANI NADZORNI I UPRAVLJAČKI SUSTAVI (CNUS)',
  '_AUTOMATSKA_REGULACIJA_NASLOV_2': 'OSNOVNE FUNKCIJE CNUS-A',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_1': 'Automatska regulacija osigurava energetski efikasan rad sustava.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_2': 'Naša snaga ogleda se u tome da smo u mogućnosti projektirati, programirati, montirati, pustiti u rad i servisirati i najkompliciranije sustave automatske regulacije (Siemens PX, Siemens SYNCO, Johnson Controls FX, Johnson Controls FAC).',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_3': 'Imamo više od 10 godina iskustva u sustavima automatske regulacije na različitim tipovima objekata, od pametnih kuća do kompleksnih sustava centralnog nadzora (CNS) i upravljanja na velikim zgradama.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_4': 'Rješenja koja nudimo našim klijentima:',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_5': 'Inovativna rješenja u sustavima automatizacije klijentima osiguravaju značajno smanjenje troškova energije, kao i smanjenje ostalih troškova pri eksploataciji sustava uz povećan komfor.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_6': 'Radi pojednostavljenog upravljanja i praćenja rada opreme, kao i radi smanjenja potrošnje danas se sve više koriste centralni nadzorni sustavi ili CNUS.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_7': 'Centralizirani Nadzorni i Upravljački Sustav (CNUS) je računalno bazirani kontrolni sustav ugrađen u objekt, a koji regulira i prati stanje električne i mehaničke opreme kao što je oprema za klimatizaciju, grijanje, hlađenje, rasvjetu, protupožarnu zaštitu, sigurnosne sustave i dr.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_8': 'S energetskog stajališta daleko najveći dio ( i do 80 %) ukupne energije u objektima se koristi za klimatiziranje zraka (hlađenje, grijanje, odvlaživanje, ventiliranje) te pripremu tople sanitarne vode.',
  '_AUTOMATSKA_REGULACIJA_PARAGRAF_9': 'Shodno tomu, isplati se uložiti u elemente upravljačkog sustava koji će optimizirati potrošnju energije, pojednostaviti upravljanje i regulaciju, te povećati ugodnost boravka u prostorima.',
  '_AUTOMATSKA_REGULACIJA_LIST_1.1': 'sustave upravljanja klimatizacijom, grijanjem i hlađenjem',
  '_AUTOMATSKA_REGULACIJA_LIST_1.2': 'sustave upravljanja energijom',
  '_AUTOMATSKA_REGULACIJA_LIST_1.3': 'upravljanje rasvjetom',
  '_AUTOMATSKA_REGULACIJA_LIST_1.4': 'centralne nadzorno upravljačke sustave (CNUS)',
  '_AUTOMATSKA_REGULACIJA_LIST_2.1': 'Upravljanje sustavom',
  '_AUTOMATSKA_REGULACIJA_LIST_2.2': 'Nadziranje rada sustava',
  '_AUTOMATSKA_REGULACIJA_LIST_2.3': 'Optimiziranje troškova energije',

  //MONTAZA
  '_MONTAZA_NASLOV_1': 'INTERNI TEHNIČKI PRIJEM KAO OSIGURANJE KVALITETE',
  '_MONTAZA_PARAGRAF_1': 'Pravilan i pouzdan način montaže garantira ispravnost i učinkovitost sustava , zbog toga montažne timove Alfa Therma čine isključivo specijalizirani i educirani djelatnici.',
  '_MONTAZA_PARAGRAF_2': 'Propisane procedure montaže su dodatne garancija uspješno izvršenih projekata.',
  '_MONTAZA_PARAGRAF_3': 'Dodatna podrška montažerima je radionica , u kojoj izrađujemo i pripremamo elemente neophodne za integraciju cijelog sustava.',
  '_MONTAZA_PARAGRAF_4': 'Skladišnim kapacitetima omogućujemo dostupnost svih elemenata, što uvelike olakšava, ali i daje dodatnu sigurnost našim klijentima.',
  '_MONTAZA_PARAGRAF_5': 'Da bi se provjerile sve tehničke karakteristike sustava te korektnost izvedenih radova na montaži, Alfa Therm organizira interni tehnički prijem kako bismo mi sami detektirali i otklonili moguća odstupanja u izvođenju montaže.',

  //SERVIS
  '_SERVIS_PARAGRAF_1': 'Dostupnost servisa 24 sata , sedam dana u tjednu potvrđuje našu opredjeljenost za brigu o klijentima.',
  '_SERVIS_PARAGRAF_2': 'Naš servis centar je osposobljen da brzo i kvalitetno detektira kvar te u dogovoru sa klijentom sanira kvar na optimalan i brz način, zahvaljujući internom skladištu rezervnih djelova.',
  '_SERVIS_PARAGRAF_3': 'Servisni timovi Alfa Therm-a čine specijalizirani serviseri sa zaduženim servisnim vozilom opremljenim sofisticiranim alatima.',
  '_SERVIS_PARAGRAF_4': 'Redovita obuka servisera, kako od strane proizvođača opreme, tako i naša interna obuka osiguravaju brze i kvalitetne servisne intervencije kao i ispravnost rada opreme puštene u rad.',
  '_SERVIS_PARAGRAF_5': 'Propisane procedure za izvršenje servisnih intervencija i procedure zaštite na radu kao i protupožarne zaštite u servisu su imperativ u svakodnevnom radu.',

  //ODRZAVANJE
  '_ODRZAVANJE_PARAGRAF_1': 'Preventivno održavanje se pokazalo kao jedna od najboljih usluga, ali i nabolji izbor naših Klijenata.',
  '_ODRZAVANJE_PARAGRAF_2': 'Tehničkim i higijenskim preventivnim održavanjem želimo uvesti novu kvalitetu života i rada u prostorima naših klijenata, a samim time smanjiti intervencije u sezoni grijanja ili hlađenja.',
  '_ODRZAVANJE_PARAGRAF_3': 'Naš odjel servisa izrađuje plan preventivnog održavanja tako da opremu održava prema izrađenom rasporedu. Dežurni servisni timovi mogu biti na raspolaganju po potrebi i 24 sata na dan, ukoliko se to zahtjeva ugovorom. Servisni odjel vodi brigu o usavršavanju kadrova i stalnim zalihama rezervnih dijelova.',
  '_ODRZAVANJE_PARAGRAF_4': 'Na nas se možete osloniti 24 sata na dan.',

  //ENERGETSKI AUDIT
  '_ALFA_THERM': 'Alfa Therm d.o.o. Mostar ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.1': 'je od strane Federalnog ministarstva prostornog uređenja dana 03.04.2015.godine ishodio ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.2': 'ovlaštenje broj UPI/03-23-2-341/14 SK ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.3': 'za provođenje energetskih pregleda i energetsko certificiranje stambenih zgrada i nestambenih objekata sa ',
  '_ENERGETSKI_AUDIT_PARAGRAF_1.4': 'složenim tehničkim sistemom. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.1': 'Energetski audit (pregled) ',
  '_ENERGETSKI_AUDIT_PARAGRAF_2.2': 'je postupak kojim se analizira postojeća potrošnja i energetska svojstva zgrade, te određuje isplativost investicije i mjere uštede energije. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.1': 'Energetski certifikat ',
  '_ENERGETSKI_AUDIT_PARAGRAF_3.2': 'je dokument koji utvrđuje energetski razred zgrade, predočuje energetske karakteristike zgrade i daje informaciju o potrošnji energije te stanju zgrade u odnosu na energetsku učinkovitost. Energetski certifikat obuhvaća i prijedlog mjera za isplativo poboljšanje energetskih karakteristika zgrade kako bi se smanjila potrošnja energije dobivenih energetskim auditom. ',
  '_ENERGETSKI_AUDIT_PARAGRAF_4': 'Uštedom energije i korištenjem obnovljivih izvora energije štedimo osobna sredstava, a energetski razred zgrade može utjecati na njenu tržišnu vrijednost. Ovisno o potrošnji energije objekt se smješta u jedan od 8 energetskih razreda: A+, A, B, C, D, E, F, G.',
  '_ENERGETSKI_AUDIT_PARAGRAF_5': 'Poboljšanje Energetske učinkovitosti vaše zgrade može popraviti kvalitetu života vama i vašim ukućanima, produljiti vijek građevine, smanjiti utrošak energije, a time i troškove održavanja.',

  //REFERENCE
  '_JAVNE_USTANOVE': 'Javne ustanove',
  '_OBJEKTI_POSEBNE_NAMJENE': 'Objekti posebne namjene',
  '_OSTALI_OBJEKTI': 'Ostali objekti',
  '_POSLOVNE_ZGRADE': 'Poslovne zgrade',
  '_PRODAJNI_CENTRI': 'Prodajni centri',
  '_SAKRALNI_OBJEKTI': 'Sakralni objekti',
  '_UGOSTITELJSKI_OBJEKTI': 'Ugostiteljski objekti'
};
