import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../service/translate/translate.service';
import { SiteService } from '../../service/site.service';

@Component({
  selector: 'at-cookie',
  templateUrl: './cookie.component.html',
})

export class CookieComponent implements OnInit {

  public lang: string;
  public success: string;
  public error: string;
  public isCookie: boolean;

  constructor(private _translate: TranslateService) {
    this.isCookie = true;
  }

  ngOnInit() {
    this.loadText();
    this.checkCookie();
  }

  loadText() {
    this.lang = sessionStorage.getItem("Jezik");

    if (this.lang == undefined || this.lang == null)
      this.lang = 'hr';

    this._translate.use(this.lang);
  }

  checkCookie() {
    if (localStorage.getItem("Cookie") != null || localStorage.getItem("Cookie") != undefined) {
      var cookie = localStorage.getItem("Cookie").toLowerCase();
      this.isCookie = cookie == 'true' ? true : false;
    }
  }

  ProcitajVise() {
    $(document).ready(function () {
      $("#vise").hide();
      $(".hidden-data").show();
    })
  }

  accept() {
    this.isCookie = false;
    localStorage.setItem("Cookie", 'false');
    $(".cookie").hide();
  }
}
